import env from "@/configs/env";
import SecurityManager, {
    IRefreshTokenPlatform,
    PlatformEnum,
} from "@/managers/SecurityManager";
import type {AxiosInstance, AxiosRequestConfig} from "axios";
import axios from "axios";
import JwtTokenService from "./JwtTokenService";
import {$notify, notificationType} from "@/notify";
import {useRouter} from "vue-router";


const router = useRouter();

export default abstract class ApiService {
    private static createAuthInterceptor(
        axiosConfig: AxiosRequestConfig
    ): AxiosRequestConfig {
        const security = localStorage.getItem("security");
        const token = security ? JSON.parse(security).token : undefined;
        if (!token) {
            return axiosConfig;
        }
        return {
            ...axiosConfig,
            headers: {
                ...axiosConfig.headers,
                Authorization: `bearer ${token}`,
            },
        };
    }

    refreshTokenPromise: Promise<string> | undefined;

    static createApi(): AxiosInstance {
        const client = axios.create({
            baseURL: env.API_V2,
        });

        client.interceptors.request.use(this.createAuthInterceptor);

        client.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                if (error.response.status === 401) {
                    const security = localStorage.getItem("security");
                    const refreshToken = security
                        ? JSON.parse(security).refreshToken
                        : undefined;
                    if (refreshToken) {
                        const newToken = await SecurityManager.refreshToken(refreshToken)
                        error.config.headers["Authorization"] = `bearer ${newToken}`;
                        return axios(error.config);
                    }
                    await router.push({name: 'login'});
                } else if (error.response.status === 403) {
                    $notify.addNotif({
                        title: 'influencer.errorTitle',
                        content: "global.error403",
                        icon: 'mdi-error',
                        type: notificationType.ERROR
                    });
                } else if (error.response.status > 499 && error.response.status < 600) {
                    $notify.addNotif({
                        title: 'influencer.errorTitle',
                        content: "global.error500",
                        icon: 'mdi-error',
                        type: notificationType.ERROR
                    });
                } else {
                    return Promise.reject(error);
                }
            }
        );

        return client;
    }
}
