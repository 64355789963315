import JwtTokenService from "@/services/JwtTokenService";

export default abstract class CrispService {
    static reset(): void {
        delete window.CRISP_TOKEN_ID;
        window.$crisp.push(["do", "session:reset"])
    }
    static configure(): void {
        if (!window.$crisp.get || !window.$crisp.push){
            return;
        }

        if (window.$crisp.get("user:email")) {
            return;
        }

        const username = JwtTokenService.getPayload()?.username;
        const externalUuid = JwtTokenService.getPayload()?.externalUuid;

        if (username && externalUuid) {
            window.$crisp.push(['set', 'user:email', [username]]);
            window.CRISP_TOKEN_ID = externalUuid;
        }

        window.$crisp.push(['do', 'chat:hide']);

        window.$crisp.push(['on', 'chat:closed', function () {
            window.$crisp.push(['do', 'chat:hide']);
        }]);
    }
}
