export default {
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have an account"])},
  "api": {
    "another_instagram_account_connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another Instagram account is linked to this account. Contact support for more information."])},
    "instagram_account_connected_to_another_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Instagram account is already linked to another email address. Contact support for more information."])},
    "missing_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please accept all permissions asked."])},
    "no_instagram_business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You didn't linked any Instagram Business."])},
    "too_many_instagram_business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have linked more than one Instagram Business."])}
  },
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to login"])},
  "cgu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the Terms and Conditions"])},
  "companyLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login as Company"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "errorAlreadyExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already exist"])},
  "errorInvalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credentials"])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
  "facebookHelp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Need help to use an Business Instagram account? ", _interpolate(_named("notice")), " "])},
  "forgotSumUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your e-mail address to receive instructions for resetting your password."])},
  "forgotTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])},
  "instagramAuthenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticating on Instagram"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "loginSumUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nice to see you again, log in to your account!"])},
  "loginTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login as Influencer"])},
  "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a look at our notice !"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passwords"])},
  "politics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy."])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "registerSumUp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Create your account to access dozen of campaigns. By registering, you agree to  ", _interpolate(_named("cgu")), " and the  ", _interpolate(_named("politics")), " ."])},
  "registerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as an Influencer"])},
  "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
  "resendRefreshTokenSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new confirmation mail was sent"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
  "resetSumUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a new password."])},
  "resetTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset your password"])},
  "sendNewVerificationMailTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a new confirmation mail to  "])},
  "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll receive a confirmation mail in a few minutes! Check your mail box."])},
  "successPasswordForgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email has been sent to you."])},
  "successPasswordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations, you're password has been changed!"])},
  "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "verifyUserMailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You're mail validation failed."])},
  "verifyUserMailSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've validated your e-mail, you c an login to the platform"])}
}