<template>
  <v-container clss="h-screen">
<!--    <v-card-title>{{ $t("payments.myPayments.title") }}</v-card-title>-->
    <v-card
      class="d-flex align-center justify-center"
      height="50vh"
      variant="flat"
    >
      <div class="d-flex flex-column justify-center align-center">
        <v-card-text class="mb-10 text-h6 font-weight-regular">
          {{ $t("payments.myPayments.noInfoText") }}
        </v-card-text>

        <v-btn class="text-none text-center" color="primary" :to="{ name: 'influencer-profile', query:{toFacturation:true} }"  size="large">
          {{ $t("payments.myPayments.register") }}
        </v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
.centered {
  height: 50vh;
}
</style>
