export default {
    errors: (errors: any) => {
        if (errors.length === 0) {
            return '';
        }

        return errors.map((error: any) => error.$message);
    },
    toCamelCase: (str: string) => {
        return str.replace(/([-_][a-z])/gi, ($1) => {
            return $1.toUpperCase().replace('-', '').replace('_', '');
        });
    }
};