export default {
  "myPayments": {
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je postule"])},
    "giftCardDialog": {
      "signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un problème avec ma carte cadeau"])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ta carte cadeau a été générée le ", _interpolate(_named("date")), ", tu devrais la recevoir à l'adresse ", _interpolate(_named("mail"))])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir ma carte cadeau!"])}
    },
    "giftCardPendingDialog": {
      "signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un problème avec mes publications"])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La campagne ", _interpolate(_named("title")), " est en cours. Tu pourras obtenir ta carte cadeau une fois tes publications postées"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir ma carte cadeau ! 🎁"])}
    },
    "giftCardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes Cadeaux"])},
    "giftCardsList": {
      "brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la campagne"])},
      "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la campagne"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut du paiement"])},
      "statusValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
      "statusWaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de validation"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])}
    },
    "invoicePendingDialog": {
      "signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un problème avec mes publications"])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La campagne ", _interpolate(_named("title")), " est en cours. Tu pourras obtenir ton paiement une fois tes publications validées par le client."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir mon paiement  ! 💵"])}
    },
    "invoicesList": {
      "brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la campagne"])},
      "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la campagne"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut du paiement"])},
      "statusValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
      "statusWaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de validation"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])}
    },
    "invoicesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
    "noCampaignText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postule aux campagnes qui t'intéressent pour commencer à être rémunéré."])},
    "noInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu souhaites recevoir automatiquement la rémunération de tes campagnes ?"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer mes infos"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes paiements"])}
  }
}