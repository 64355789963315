export default abstract class BrowserService {
    public static IN_APP_BROWSER = [
        {'label': 'messenger', 'regex': /\bFB[\w_]+\/(Messenger|MESSENGER)/},
        {'label': 'facebook', 'regex': /\bFB[\w_]+\//},
        {'label': 'twitter', 'regex': /\bTwitter/i},
        {'label': 'line', 'regex': /\bLine\//i},
        {'label': 'wechat', 'regex': /\bMicroMessenger\//i},
        {'label': 'puffin', 'regex': /\bPuffin/i},
        {'label': 'miui', 'regex': /\bMiuiBrowser\//i},
        {'label': 'instagram', 'regex': /\bInstagram/i},
    ];

    public static getUserAgent(): string {
        return navigator.userAgent || navigator.vendor || window.opera;
    }

    public static getBrowserLabel(): string {
        return this.IN_APP_BROWSER.filter((browser) => browser.regex.test(this.getUserAgent()))[0]?.label || 'other';
    }

    public static isInAppBrowser(): boolean {
        return this.getBrowserLabel() !== 'other';
    }
}