import {createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw} from "vue-router";
import HomeCard from "@/components/HomeCard.vue";
import LoginView from "@/views/security/LoginView.vue";
import RegisterView from "@/views/security/RegisterView.vue";
import ForgotView from "@/views/security/ForgotView.vue";
import ResetView from "@/views/security/ResetView.vue";
import MyPaymentsView from "@/views/payments/MyPaymentsView.vue";
import MyStatsView from "@/views/statistics/MyStatsView.vue";
import AccountSettingsView from "@/views/user/AccountSettingsView.vue";
import CampaignsView from "@/views/campaigns/CampaignsView.vue";
import ChatView from "@/views/user/ChatView.vue";
import SecurityManager from "@/managers/SecurityManager";
import MyCampaignsView from "@/views/campaigns/MyCampaignsView.vue";
import InfluencerForm from "@/views/InfluencerForm.vue";
import UserManager from "@/managers/UserManager";
import embeddedRoutes from "@/routes/EmbeddedRoutes";
import OnBoardingView from "@/views/user/OnBoardingView.vue";
import UgcProfileView from "@/views/UgcProfileView.vue";

declare module "vue-router" {
    interface RouteMeta {
        allowAnonymous?: boolean;
        layout?: string;
    }
}

const routes: Array<RouteRecordRaw> = [
    ...embeddedRoutes,
    {
        path: "/accountSettings",
        name: "influencer-profile",
        component: AccountSettingsView,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/linkedAccount",
        name: "linked-account",
        component: InfluencerForm,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/chat",
        name: "chat",
        component: ChatView,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/chat/archive/:briefId/:clientId",
        name: "archivedChatroom",
        component: ChatView,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/chat/:chatId",
        name: "chatroom",
        component: ChatView,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/login",
        name: "login",
        component: LoginView,
        meta: {
            allowAnonymous: true,
            layout: "FullScreenLayout"
        },
    },
    {
        path: "/register",
        name: "register",
        component: RegisterView,
        meta: {
            allowAnonymous: true,
            layout: "FullScreenLayout"
        },
    },
    {
        path: "/instagram",
        name: "instagram",
        component: LoginView,
        meta: {
            allowAnonymous: true,
            layout: "FullScreenLayout"
        },
    },
    {
        path: "/password/forgot",
        name: "forgotPassword",
        component: ForgotView,
        meta: {
            allowAnonymous: true,
            layout: "FullScreenLayout"
        },
    },
    {
        path: "/password/reset",
        name: "resetPassword",
        component: ResetView,
        meta: {
            allowAnonymous: true,
            layout: "FullScreenLayout"
        },
    },
    {
        path: "/",
        name: "home",
        component: HomeCard,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/campaigns",
        name: "campaigns",
        component: CampaignsView,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/campaigns/:briefId",
        name: "campaignDetails",
        component: CampaignsView,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/myCampaigns",
        name: "myCampaigns",
        component: MyCampaignsView,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/myCampaigns/:briefId",
        name: "myCampaignsDetails",
        component: MyCampaignsView,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/messages",
        name: "messages",
        component: HomeCard,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/myPayments",
        name: "myPayments",
        component: MyPaymentsView,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/account",
        name: "account",
        component: HomeCard,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/stats",
        name: "stats",
        component: MyStatsView,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/ugc-profile/:userUgcInfluencerId",
        name: "ugcProfile",
        component: UgcProfileView,
        meta: {
            allowAnonymous: false,
        },
    },
    {
        path: "/onBoarding",
        name: "onBoarding",
        component: OnBoardingView,
        meta: {
            allowAnonymous: false,
            layout: "FullScreenLayout"
        },
    },

];

export const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// TODO : SEE HOW WE MANAGE WITH THE MULTI NETWORK
function linkedAccount(route: RouteLocationNormalized) {
    let currentRoute = route.name;
    localStorage.setItem('previousRoute', typeof currentRoute === "string" ? currentRoute : "campaigns");

    UserManager.getMe().then((user) => {
        let usernameAValidEmail = user.usernameAValidEmail
        let isLoggedWithFacebook = user.linkedToInstagram;
        let needToRefresh = user.needingToRefreshFacebookToken;
        let notFullyRegisteredUser =  !user.contactName || !user.contactSurname;

        if (!usernameAValidEmail || (isLoggedWithFacebook && needToRefresh ) || !user.userInfluencerNotFullyRegister.length || notFullyRegisteredUser  ) {
            return router.replace({name: "influencer-profile"});
        }

        if(isLoggedWithFacebook && needToRefresh ){
            return router.push({name: "linked-account"});
        }
    }).catch((error) => {
    });
}

function toOnboarding(){
    UserManager.getMe().then((user)=>{
        //if(user.userInfluencers.length === 0 || user.userInfluencers.length === user.userInfluencerNotFullyRegister.length)
        if(user.userInfluencers.length === 0){
            return router.replace({name: "onBoarding"});
        }
    }).catch((e)=>{

    })
}
router.beforeEach((to, from, next) => {
    if (!to.matched.some((record) => record.meta.allowAnonymous)) {
        let route = window.location;
        const security = localStorage.getItem("security");
        if (security && JSON.parse(security).expirationDate * 1000 > +new Date()) {
            if(to.name !== 'onBoarding') {
                toOnboarding()
            }
            // if (to.name !== 'linked-account') {
            //     linkedAccount(to);
            // }
            next();
            return;
        } else if (security && JSON.parse(security).refreshToken) {
            SecurityManager.refreshToken(JSON.parse(security).refreshToken)
                .then(() => {
                    if(to.name !== 'onBoarding') {
                        toOnboarding()
                    }
                    // if (to.name !== 'linked-account') {
                    //     linkedAccount(to);
                    // }
                    next();
                    return;
                })
                .catch(() => {
                    return router.push({name: "login"});
                });
        } else {
            return router.push({name: "login"});
        }
    }
    next();
});

export default router;
