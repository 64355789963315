import {IUserResponse} from "@/entities/User";
import env from "@/configs/env";
import ApiService from "@/services/ApiService";
import {useQuery} from "vue-query/esm";

export interface IBillingDataForm{
    value:number;
    data:number | string |undefined;
}
export interface IBillingData{
    paymentType: null | number;
    acceptMandate: null | number;
    succeed: number | string;
    firstname: null | string;
    name: null | string;
    companyName: null | string;
    companyAddress: null | string;
    companyPostalCode: null | number;
    companyCity: null | string;
    companyCountry: null | string;
    siret: null | string;
    tva: null | number
}
export interface IInfluencerStripeForm{
    adress: null | string;
    city: null | string;
    postalCode: null | string;
    firstname: any;
    name: any;
    refreshUrl:string;
    returnUrl:string;
}
export interface IInfluencerAccountLink{
    refreshUrl:string;
    returnUrl:string;
}
const axios = ApiService.createApi();

export default abstract class InfluencerManagerV1{

    private static async getInfluencerBillingDataV1(
    ):Promise<IBillingData[] | void[]>{
        const response = await axios.get(env.API_V1 + "/espaceInfluenceur/getUserBillingInformations");
        return response.data;
    }
    public static async postStripeAccountLinkV1( data:IInfluencerAccountLink
    ):Promise<IBillingData[] | void[]>{
        const response = await axios.post(env.API_V1 + "/brief/getStripeAccountLinkNew", data);
        return response.data;
    }

    public static async postInfluencerBillingDataV1(data:IBillingDataForm
    ){
        const response = await axios.post(env.API_V1 + "/espaceInfluenceur/modifiyUserBillingInformations", data);
        return response.data;
    }

    public static async postInfluencerStripeV1(data:IInfluencerStripeForm
    ){
        const response = await axios.post(env.API_V1 + "/brief/testStripeNew", data);
        return response.data;
    }


    public static useGetInfluencerBillingDataV1(){
        return useQuery('influencerBillingDataV1', this.getInfluencerBillingDataV1)
    }

}