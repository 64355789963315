import ApiService from "@/services/ApiService";
import env from "@/configs/env";
import {useQuery} from "vue-query/esm";
import {IPicture} from "@/managers/MediaManager";
import {NetworkEnum} from "@/entities/NetworkEnum";


export interface IBriefElements {
    elements: IInfluencerBrief[];
    nbPages: number,
    nbElements: number,
    nbElementsPerPage: number
}

export interface IInfluencerBrief {
    id:number,
    title:string,
    startProspectingAt:string,
    startPublishingAt:string,
    stopPublishingAt:string,
    state:string,
    briefNetworks: {
        network:NetworkEnum,
        remunerationLaws:{
            type: string,
            value: number
        }[]
    }[],
    promoCode: {code:string, percent:number},
    brand : {name: string, logo:IPicture},
    cover: IPicture,
    hasApply: boolean | null
}
const client = ApiService.createApi();

export default abstract class InfluencerBriefManager {
    private static async getInfluencerBriefStatus(): Promise<string[]>
    {
        const response = await client.get(env.API_V2 + "/influencerRole/brief/allStatus" );
        return response.data;
    }

    private static async getBriefsTitles(
    ):Promise<{id:number; title:string}[]>{
        const response = await client.get(env.API_V2 + '/influencerRole/briefs/titles');
        return response.data
    }

    static async postInfluencerBriefApplication(
        params:{
            briefId:number|null;
            briefNetworkId:number|null;
        }
    ){
        const response = await client.post(env.API_V2 + "/influencerRole/brief/" + params.briefId + "/briefNetwork/" + params.briefNetworkId + "/influencerApplication");
        return response.data;
    }

    static async postInfluencerBriefs(
        page:number| null,
        nbElementsPerPage: number,
        requestBody : {
            remunerationLaws:string,
            networks:NetworkEnum[]
        }
    ):Promise<{
        elements: IInfluencerBrief[]
    }>{
        const response = await client.post(env.API_V2 + "/influencerRole/briefs", requestBody, {params: {page:page,nbElementsPerPage:nbElementsPerPage }});
        return response.data;
    }

    static getInfluencerBriefStatusQuery(){
        return useQuery('influencerAllBriefStatus', this.getInfluencerBriefStatus);
    }

       static useGetBriefsTitles(){
        return useQuery("influencerBriefsTitles", this.getBriefsTitles);
    }
}