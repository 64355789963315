import {WritableComputedRef} from "vue";
import env from "@/configs/env";
import ApiService from "@/services/ApiService";
import {useQuery} from "vue-query/esm";

const client = ApiService.createApi()

export default class BriefTrackedMediaManager {
    static async getTrackedMedia(influencerApplicationId: number) {
        const response = await client.get(env.API_V2 + "/influencerRole/influencerApplication/" + influencerApplicationId + '/trackedMedia');
        return response.data;
    }

    static useGetTrackedMedia(
        influencerApplicationId: number
    ) {
        return useQuery(['TrackedMedia', influencerApplicationId], () => this.getTrackedMedia(influencerApplicationId));
    }

    static async postNewTrackedMedia(influencerApplicationId: number, data: number[]) {
        const response = await client.post(env.API_V2 + "/influencerRole/influencerApplication/" + influencerApplicationId + '/trackedMedia', {files: data});
        return response.data;
    }

    static async putTrackedMedia(influencerApplicationId: number, trackedMediaId: number, data: number[]) {
        const response = await client.put(env.API_V2 + "/influencerRole/influencerApplication/" + influencerApplicationId + '/trackedMedia/' + trackedMediaId, {files: data});
        return response.data;
    }
}