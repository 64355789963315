import {watch} from "vue";

export default class Utils {
    public static cloneData(source: any, destination: any, errorOnUndefinedKey = true) {
        watch(source, (data) => {
            if (data) {
                for (const key of Object.keys(data)) {
                    if (errorOnUndefinedKey && destination[key] === undefined) {
                        throw new Error(`Key "${key}" does not exist on destination`);
                    }

                    destination[key] = data && data[key];
                }
            }
        });
    }

    public static  debounce = (fn: Function, ms = 300) => {
        let timeoutId: ReturnType<typeof setTimeout>;
        return function (this: any, ...args: any[]) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => fn.apply(this, args), ms);
        };
    };
}