import {ComputedRef, Ref} from "vue";
import {useInfiniteQuery} from "vue-query";
import ChatManager from "@/managers/ChatManager";
import InfluencerBriefManager from "@/managers/Influencer/InfluencerBriefManager";
import MyCampaignsManager from "@/managers/MyCampaignsManager";

const infiniteQueries: { [useName: string]: Function } = {
    postChatSearch : ChatManager.postChatSearch,
    postInfluencerBriefsSearch : InfluencerBriefManager.postInfluencerBriefs,
    postInfluencerApplicationsSearch : MyCampaignsManager.postMyCampaigns
};

interface PageParamsType {
    page: number;
    nbElementsPerPage: number;
    sort?: string;
    body?: object;
    requestBody?: object;
    briefId?: string;
    briefNetworkId?: string;
}

export function useGlobalInfiniteQuery(useName: string, pageParams: ComputedRef<PageParamsType> | Ref<PageParamsType>) {
    let currentPage = 1;

    return useInfiniteQuery(
        [useName, { pageParams }],
        async () => {
            const response = await infiniteQueries[useName](...Object.values(pageParams.value));

            currentPage = pageParams.value.page;
            currentPage++;

            return response;
        },
        {
            getNextPageParam: (lastPage) => {
                if (lastPage.elements?.length) return (pageParams.value.page = currentPage);
                return undefined;
            },
            getPreviousPageParam: () => {
                return (pageParams.value.page = 1);
            }
        }
    );
}