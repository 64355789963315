export default {
  "analysis": {
    "community": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community age and gender"])}
    },
    "interest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community interests"])}
    },
    "location": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community location"])}
    }
  },
  "analysisTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community Analysis"])},
  "chart": {
    "men": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Men"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NA"])},
    "women": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Women"])}
  },
  "commentsAverage": {
    "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A publication get in average ", _interpolate(_named("comments")), " comments."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments average"])}
  },
  "commentsEngagement": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement rate (comments)"])}
  },
  "globalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic Statistics"])},
  "influencerCard": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email adress"])},
    "followers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followers"])},
    "followings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followings"])},
    "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])}
  },
  "likeAverage": {
    "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["A publication get in average ", _interpolate(_named("likes")), " likes."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Likes average"])}
  },
  "likesEngagement": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement rate (likes)"])}
  },
  "nbPostsPerWeek": {
    "subtitle": {
      "few": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infrequently"])},
      "toMuch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too frequently"])},
      "usual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usual"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of posts per week"])}
  },
  "publicationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
  "reach": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique views's total number"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global Reach"])}
  }
}