import env from "@/configs/env";
import ApiService from "@/services/ApiService";
import {useQuery} from "vue-query/esm";

export interface IBriefTracking{
    brief: {
        id:number;
        title:string;
    };
    trackedPosts:IPost[];
    trackedStories:IStory[];
}

export interface IPost{
    nbLikes: number | null;
    nbComments: number | null;
    mediaType: number | null;
    description: string | null;
    date: number | null;
    mediaUrl: string | null;
    mediaPreview: null;
    engagement: number | null;
    nbImpressions: number | null;
    nbReach: number | null;
    nbSaved: number | null;
    urlProfilePicture: string | null;
    username: string | null;
    nbFollowers: number | null;
    likesEngagement: number | null;
    commentsEngagement: number | null;
    mediaId: string | null;
}
export interface IStory{
    nbReach: number | null;
    mediaType: number | null;
    mediaUrl: string | null;
    date: number | null;
    nbLikes: number | null;
    caption: string | null;
    mediaPreview: null;
    nbComments: number | null;
    nbImpressions: number | null;
    nbExits: number | null;
    nbReplies: number | null;
    nbTapsForward: number | null;
    nbTapsBack: number | null;
    urlProfilePicture: string | null;
    username: string | null;
    nbFollowers: number | null;
    likesEngagement: number | null;
    commentsEngagement: number | null;
}

const client = ApiService.createApi();

export default abstract class BriefTrackingManager{

    static async getInfluencerBriefTracking(
        influencerApplicationId: number
    ): Promise<IBriefTracking> {
        const response = await client.get(env.API_V2 + "/influencerRole/influencerApplication/"+ influencerApplicationId + "/trackingDetails" );
        return response.data;
    }

    static useGetInfluencerBriefTracking(
        influencerApplicationId: number
    ) {
        return useQuery(['InfluencerBriefTracking', influencerApplicationId], () => this.getInfluencerBriefTracking(influencerApplicationId));
    }

}