import env from "@/configs/env";
import ApiService from "@/services/ApiService";
import {useInfiniteQuery, useQuery} from "vue-query/esm";
import {IPicture} from "@/managers/MediaManager";
import {Ref} from "vue";
import {useQueryClient} from "vue-query";


const client = ApiService.createApi();

export interface IChatElements {
    elements: IChat[];
    nbPage: number | null;
    nbElements: number | null;
    nbElementsPerPage: number;
}

export interface IChat {
    id: number;
    lastMessageViewedByUser: object | null;
    lastMessageViewedOfCurrentUser: number | null;
    nbMessagesNotViewedByUser: object | null;
    nbMessagesNotViewedOfCurrentUser: number | null;
    chatMessages: IChatMessage[];
    users: IChatUser[];
    influencerApplication: IChatInfluencerApplication;
}

export interface IChatMessage {
    id: number | null;
    message: string | null;
    createdBy: IChatUser | null;
    createdAt: Date | null;
}

export interface IChatUser {
    id: number | null;
    username: string | null
    allRoles: string[] | null;
    contactSurname: string | null;
    contactName: string | null
    profilePicture: IPicture
}

export interface IChatInfluencerApplication {
    id:number | null;
    briefNetwork: IChatBriefNetwork;
    influencer: object | null;
    state: string | null;
    remuneration: number| null;
}

export interface IChatBriefNetwork {
    id: number | null;
    brief: IChatBrief | null;
    network: string | null;
}

export interface IChatBrief {
    id: number | null;
    title: string | null;
    state: string | null;
    brand:IChatBrand;
}
export interface IChatBrand {
    name:string;
    logo:IPicture
}

export interface IChatSearch{
    influencerUsername: string | null;
    brief: number | null;
    influencerApplicationStates?: string[];
    briefState: string | null;
    brandName: string | null;
}

export default abstract class ChatManager {

    static async getChat(
        id: number
    ): Promise<IChat> {
        const response = await client.get(env.API_V2 + "/chat/" + id);
        return response.data;
    }

    private static async getChatNbMessageNotViewed(

    ): Promise<{ nbMessagesNotViewed: number }> {
        const response = await client.get(env.API_V2 + "/chat/nbMessageNotViewed");
        return response.data;
    }

    static async postChatSearch(
        page: number | null,
        nbElementsPerPage: number,
        requestBody: IChatSearch

    ):Promise<IChatElements> {
        const response = await client.post(env.API_V2 + "/chat/search", requestBody,
            {params: {
                page: page,
                nbElementsPerPage: nbElementsPerPage
            }
            });
        return response.data;
    }

    static async postChatMessage(
        data: { message: string | null, attachments?:string[] },
        id: number | null
    ) {
        const response = await client.post(env.API_V2 + "/chat/" + id + "/message", data);
        return response.data;
    }

    static async patchChatMessageNotViewed(chatId: number | null, messageId: number | null) {
        const response = await client.patch(env.API_V2 + "/chat/" + chatId + "/message/" + messageId + "/notViewed");
        return response.data;
    }


    static useGetChat(
        id: Ref<number>,
        { enabled }: { enabled: boolean } = { enabled: true }
    ) {
        const queryClient = useQueryClient();
        return useQuery(['chat', id], () => this.getChat(id.value), {
            onSuccess: async (data) => {
                await queryClient.invalidateQueries('postChatSearch')
                await queryClient.invalidateQueries('chatNbMessageNotViewed')
            },
            enabled
        });
    }

    static useGetChatNbMessageNotViewed() {
        return useQuery('chatNbMessageNotViewed', this.getChatNbMessageNotViewed);
    }

}