import ApiService from "@/services/ApiService";
import { useQuery } from "vue-query/esm";
import MediaManager, {IPicture} from "@/managers/MediaManager";

export interface IPayement {
  briefNetwork: {
    brief: {
      brand: {
        id: number;
        logo: IPicture;
        name: string;
      };
      id: number;
      title: string;
    };
  };
  id: number;
  influencerPaymentHistoryV2: {
    amount: number;
    invoice: {
      id : number;
      signedUrl: string;
      url: string;
      urlWithToken: string;
    };
    id: number;
    isStripeActive: boolean;
    payedAt: Date;
  };
  state: string;
  remuneration: number;
}

const api = ApiService.createApi();

export default abstract class PaymentsManager {
  private static async getPaymentHistory(): Promise<IPayement[]> {
    const response = await api.get("/influencerRole/me/paymentHistory");
    return response.data;
  }

  static useGetPaymentHistory() {
    return useQuery("paymentHistory", this.getPaymentHistory);
  }
}
