export default {
  "myPayments": {
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "giftCardDialog": {
      "signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a problem with my gift card"])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your gift card was generated on ", _interpolate(_named("date")), ", you should receive it at ", _interpolate(_named("mail")), "."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive my gift card!"])}
    },
    "giftCardPendingDialog": {
      "signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a problem with my publications"])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("title")), " campaign is underway. You will be able to get your gift card once your publications are posted"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive my gift card! 🎁"])}
    },
    "giftCardTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift Cards"])},
    "giftCardsList": {
      "brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the brief"])},
      "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the campaign"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment status"])},
      "statusValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "statusWaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending validation"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])}
    },
    "invoicePendingDialog": {
      "signal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a problem with my publications"])},
      "text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("title")), " campaign is underway. You will be able to get your payment once your publications have been validated by the client."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive my payment! 💵"])}
    },
    "invoicesList": {
      "brief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the brief"])},
      "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the campaign"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment status"])},
      "statusValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "statusWaiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending validation"])},
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])}
    },
    "invoicesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices"])},
    "noCampaignText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply to the campaigns that interest you to start getting paid."])},
    "noInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to be paid automatically for your campaigns?"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save my info"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My payments"])}
  }
}