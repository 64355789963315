export default {
  "actionMenu": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler ma candidature"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un message"])},
    "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir mes publications enregistrées"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un problème"])}
  },
  "briefUgcUploadContent": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe tes contenu Ugc"])}
  },
  "cancelDialog": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dis-nous pourquoi."])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es-tu sûr de vouloir annuler ta candidature à la campagne?"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler ma candidature"])}
  },
  "cardUgc": {
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu supprimé avec succès"])},
    "sendUgc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer mes contenus UGC à la marque"])},
    "ugcRefused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenus UGC refusés"])},
    "ugcSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenus UGC envoyés"])},
    "updateContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mes contenus  "])},
    "uploadSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenus UGC envoyés avec succès"])},
    "validatedByBrand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenus UGC validés par la marque"])}
  },
  "endowment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dotation de produit"])},
  "reportDialog": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du problème rencontré"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème rencontré"])},
    "reasons": {
      "noAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La marque ne me répond pas."])},
      "noProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas reçu le produit / service"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un problème sur la campagne"])}
  },
  "select": {
    "addedByCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invité(e)"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les statuts"])},
    "applicationCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé(e)"])},
    "hasPosted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications en cours de validation par la marque"])},
    "postValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagne Terminée"])},
    "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusé(e)"])},
    "reported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signalé(e)"])},
    "selectAStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisis un statut de campagne"])},
    "underStudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours d'étude"])},
    "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé(e), en attente des publications"])},
    "validatedV1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé(e)"])},
    "validationNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de validation"])}
  },
  "table": {
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la campagne"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rémunération"])},
    "promoCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code promo"])},
    "publicationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date(s) de publication"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])}
  },
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes campagnes"])}
}