import {reactive} from "vue";

export enum notificationType {
    ERROR = 'error',
    SUCCESS = 'success',
    WARNING = 'warning',
    INFO = 'info'
}

export interface Notification {
    title: string,
    content: string,
    icon: string,
    type: notificationType
}

export default class Notify {
    notifications: Notification[];

    constructor() {
        this.notifications = [];
    }

    addNotif(notification: Notification) {
        this.notifications.unshift(notification);
        let that = this;
        setTimeout((() => that.notifications.pop()), 3000);
    }
}

export const $notify = reactive(new Notify());