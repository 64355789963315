import {IPromoCodeBriefDetails} from "@/managers/BriefManager";
import {IBriefDetails} from "@/managers/BriefManager";

export default class BriefService {
    public static personalizePromoCode(promoCode: IPromoCodeBriefDetails, username: string): string {
        return (promoCode.code??'').replace('((username))', username).replace('((percent))', ''+promoCode.percent);
    }

    public static personalizeWebLinkWithUtm(webLinkWithUtm: IBriefDetails, username: string): string {
        return (webLinkWithUtm.webLinkWithUtm??'').replace('{{influencer_pseudo}}', username);
    }
}