import {IInfluencerForm, IInfluencerUgcForm, IUserResponse} from "@/entities/User";
import ApiService from "@/services/ApiService";
import { useQuery } from "vue-query/esm";
import { IPicture } from "@/managers/MediaManager";
import { Ref, WritableComputedRef } from "vue";
import {
  FacebookCodeCredentials,
  FacebookTokenCredentials,
} from "@/types/security/FacebookLogin";
import { Idiom, Theme } from "@/managers/Influencer/DefaultManager";
import {useMutation, useQueryClient} from "vue-query";
import { IPortfolioUploadedFile } from "@/managers/Influencer/PortfolioContentManager";


const client = ApiService.createApi();

export interface IUserForm {
  name: string | null;
  surname: string | null;
  profilePicture: number | any;
}

export interface IUserInstagram {
  userId: string;
  accessToken: string;
}

export interface IUserInfluencerNetworksForm {
  tiktok: string | null;
  youtube: string | null;
  twitch: string | null;
  snapchat: string | null;
}
export interface IInstagramProfilePictureData {
  height: number;
  is_silhouette: boolean;
  url: string;
  width: number;
}
export interface IInstagramProfilePicture {
  data: IInstagramProfilePictureData;
}

export interface IInfluencerRoleMeInstagramInformations {
  last_name: string | null;
  first_name: string | null;
  birthday: string | null;
  gender: string | null;
  picture: IInstagramProfilePicture | null;
}

export interface IInfluencerRoleMeUgcInformations {
  id: number;
  influencerClass: string;
  influencerForm: {
    username: string;
    accepted: boolean;
    age: number;
    biography: string;
    birthdate: string;
    device: string;
    gender: number;
    human: boolean;
    idioms: Idiom[];
    localisation: string;
    portfolioWebLink: string;
    profilePicture: IPortfolioUploadedFile;
    subscribedAt: number;
    themes: string[];
    uploadedPortfolioFile: {
      id: number;
      mimeType: string;
      originalFilename: string;
      signedUrl: string;
    };
  };
}

export interface IUserInfluencerNetworks {
  networks: IUserInfluencerNetworksForm;
}

export interface IUserEmail {
  email: string;
  currentPassword: string;
}

export interface IUserPassword {
  password: string;
  currentPassword: string;
}

export interface IInfluencerFacturationForm {
  companyName: string;
  companyAddress: string;
  companyZipCode: string | number;
  companyCity: string;
  companyCountry: string;
  companySiret: string | number;
  acceptMandate: boolean;
  acceptTva: boolean;
  paymentType: number;
}

export interface IInfluencerFacturation {
  companyName: string;
  companyAddress: string;
  companyZipCode: string | number;
  companyCity: string;
  companyCountry: string;
  companySiret: string | number;
  acceptMandate: boolean;
  acceptTva: boolean;
  paymentType: number;
  stripeAccountId: boolean;
}

export interface IUserInfluencerFormRequest {
  gender: any | null;
  localisation: string | null;
  birthdate: any | null;
  themes: WritableComputedRef<string[]> | string[] | null;
}
export interface IUserUgcInfluencerFormRequest {
  gender: any | null;
  localisation: string | null;
  birthdate: any | null;
  biography: string|null;
  human: boolean;
  device: string;
  profilePicture: number | null;
  username: string | null;
  idioms: number[];
  ugcThemes:string[]
}

export interface IUserDelivery {
  contactAddress: string | null | undefined;
  contactZipCode: string | null | undefined;
  contactCity: string | null | undefined;
  contactCountry: string | null | undefined;
  contactPhoneNumber: string | null | undefined;
}

export interface IUserUgcInfluencer {
  id: number;
  user: {
    id: number;
    username: string;
  };
  influencerForm: {
    id: number;
    subscribedAt: number;
    accepted: true;
    gender: number;
    localisation: string;
    age: number;
    userInfluencer: {
      id: number;
      user: string;
      influencerForm: string;
      influencerClass: string;
    };
    themes: Theme[];
    profilePicture: IPicture;
    biography: string;
    username: string;
    human: boolean;
    device: string;
    idioms: Idiom[];
    keywords: string;
  };
  influencerClass: string;
}

export default abstract class UserManager {
  static async getMe(): Promise<IUserResponse> {
    const response = await client.get("/me");
    return response.data;
  }

  static async getMeInfluencer(
    userInfluencerId: number
  ): Promise<IInfluencerForm| IInfluencerUgcForm> {
    const response = await client.get("/influencerRole/me/" + userInfluencerId);
    return response.data;
  }

  static async getMeInstagramInformations(): Promise<IInfluencerRoleMeInstagramInformations> {
    const response = await client.get(
      "influencerRole/me/instagramInformations"
    );
    return response.data;
  }

  static async getMeUgc(id: number): Promise<IInfluencerRoleMeUgcInformations> {
    const response = await client.get(
      "influencerRole/me/userUgcInfluencer/" + id + "/ugc"
    );
    return response.data;
  }

  static async getMeInfluencerDelivery(): Promise<IUserDelivery> {
    const response = await client.get("/influencerRole/me/delivery");
    return response.data;
  }

  private static async getMeInfluencerFacturation(): Promise<IInfluencerFacturation> {
    const response = await client.get("/influencerRole/me/facturation");
    return response.data;
  }

  static async getMeInfluencerNetworks(): Promise<IUserInfluencerNetworks> {
    const response = await client.get("/influencerRole/me/networks");
    return response.data;
  }

  private static async getMeThemes(): Promise<Theme[]> {
    const response = await client.get("/theme");
    return response.data;
  }

  static async patchMeInstagram(
    facebookCredentials: FacebookTokenCredentials | FacebookCodeCredentials
  ) {
    const response = await client.patch("/me/instagram", facebookCredentials);
    return response.data;
  }

  static async patchMe(data: IUserForm): Promise<IUserResponse> {
    const response = await client.patch("/me", data);
    return response.data;
  }

  static async patchMeInfluencerDelivery(
    data: IUserDelivery
  ): Promise<IUserDelivery> {
    const response = await client.patch("/influencerRole/me/delivery", data);
    return response.data;
  }

  static async patchMeInfluencerFacturation(
    data: IInfluencerFacturationForm
  ): Promise<IInfluencerFacturation> {
    const response = await client.patch("/influencerRole/me/facturation", data);
    return response.data;
  }

  static async patchMeEmail(data: IUserEmail): Promise<IUserResponse> {
    const response = await client.patch("/me/email", data);
    return response.data;
  }

  static async patchMePassword(data: IUserPassword): Promise<IUserResponse> {
    const response = await client.patch("/me/password", data);
    return response.data;
  }
  static async patchMeConfiguration(data: {
    email: string;
    password: string;
  }): Promise<IUserResponse> {
    const response = await client.patch("/me/configuration", data);
    return response.data;
  }

  static async patchMeInfluencerNetworks(
    data: IUserInfluencerNetworksForm
  ): Promise<IUserInfluencerNetworks> {
    const response = await client.patch("/influencerRole/me/networks", data);
    return response.data;
  }

  static async getUserTypeAndIsFullyRegistered(): Promise<{
    role: string;
    isFullyRegistered: boolean;
  }> {
    try {
      const userMe = await this.getMe();
      if (userMe.allRoles.find((item) => item === "ROLE_NON_INFLUENCEUR")) {
        return { role: "ROLE_BRAND", isFullyRegistered: true };
      } else {
        return {
          role: "ROLE_INFLUENCER",
          isFullyRegistered: !(
            !userMe.userInfluencers.length
            //|| userMe.userInfluencers.length ===
              //userMe.userInfluencerNotFullyRegister.length
          ),
        };
      }
    } catch (error) {
      throw new Error("Something went wrong.");
    }
  }

  static useGetMe() {
    return useQuery("userMe", this.getMe);
  }

  static useGetMeThemes() {
    return useQuery(["themes"], this.getMeThemes);
  }

  static useGetMeUgc(
    id: number,
    { enabled }: { enabled: boolean } = { enabled: true }
  ) {
    return useQuery(["userMeUgc", id], () => this.getMeUgc(id), { enabled });
  }

  static useGetMeInfluencer(
    userInfluencerId: Ref<number>,
    { enabled }: { enabled: boolean } = { enabled: true }
  ) {
    return useQuery(
      ["userMeInfluencer", userInfluencerId],
      () => this.getMeInfluencer(userInfluencerId.value),
      { enabled }
    );
  }

  static useGetMeInfluencerDelivery() {
    return useQuery("influencerDelivery", this.getMeInfluencerDelivery);
  }

  static useGetMeInfluencerFacturation() {
    return useQuery("meInfluencerFacturation", this.getMeInfluencerFacturation);
  }

  static useGetMeInfluencerNetworks() {
    return useQuery("influencerNetworks", this.getMeInfluencerNetworks);
  }

  static async postUserExternalAdd(
    userId: number,
    params: { uploadedFile: string; webLink: string }
  ) {
    const response = await client.post(
      "/influencerRole/me/userInfluencer/" + userId + "/external/add",
      params
    );
    return response.data;
  }

  static async postMeUgc(): Promise<IUserUgcInfluencer> {
    const response = await client.post("/influencerRole/me/ugc");
    return response.data;
  }

  static usePostMeUserUgcMutation() {
    return useMutation("newUserUgcInfluencer", this.postMeUgc);
  }



  static async postMeInfluencer(
      userInfluencerId: number,
      data: IUserInfluencerFormRequest
  ): Promise<IInfluencerForm> {
    const response = await client.post(
        "/influencerRole/me/" + userInfluencerId + "/influencerForm",
        data
    );
    return response.data;
  }
  static async patchMeInfluencer(
      userInfluencerId: number,
      id:number,
      data: IUserInfluencerFormRequest
  ): Promise<IInfluencerForm> {
    const response = await client.patch(
        "/influencerRole/me/" + userInfluencerId + "/influencerForm/" + id,
        data
    );
    return response.data;
  }

  static async postMeUgcInfluencer(
      userInfluencerId: number,
      data: IUserUgcInfluencerFormRequest
  ): Promise<IInfluencerUgcForm> {
    const response = await client.post(
        "/influencerRole/me/" + userInfluencerId + "/influencerUgcForm",
        data
    );
    return response.data;
  }

  static async patchMeUgcInfluencer(
      userInfluencerId: number,
      id:number,
      data: IUserUgcInfluencerFormRequest
  ): Promise<IInfluencerUgcForm> {
    const response = await client.patch(
        "/influencerRole/me/" + userInfluencerId + "/influencerUgcForm/" + id,
        data
    );
    return response.data;
  }
  static usePostMeUgcInfluencer(){
    const queryClient = useQueryClient();
    return  useMutation((requestBody:{
          userInfluencerId:number,
          data:IUserUgcInfluencerFormRequest
        }) => UserManager.postMeUgcInfluencer(requestBody.userInfluencerId, requestBody.data),
        {
          onSuccess: async (data) => {
            await queryClient.invalidateQueries('userMe');
            await queryClient.invalidateQueries('userMeInfluencer');
          }
        }
    );
  }
  static usePatchMeUgcInfluencer(){
    const queryClient = useQueryClient();
    return  useMutation((requestBody:{
          userInfluencerId:number,
          influencerFormId:number,
          data:IUserUgcInfluencerFormRequest
        }) => UserManager.patchMeUgcInfluencer(requestBody.userInfluencerId,requestBody.influencerFormId, requestBody.data),
        {
          onSuccess: async (data) => {
            await queryClient.invalidateQueries('userMe');
            await queryClient.invalidateQueries('userMeInfluencer');
          }
        }
    );
  }
  static usePostMeInfluencer(){
    const queryClient = useQueryClient();
    return  useMutation((requestBody:{
          userInfluencerId:number,
          data:IUserInfluencerFormRequest
        }) => UserManager.postMeInfluencer(requestBody.userInfluencerId, requestBody.data),
        {
          onSuccess: async (data) => {
            await queryClient.invalidateQueries('userMe');
            await queryClient.invalidateQueries('userMeInfluencer');
          }
        }
    );
  }
  static usePatchMeInfluencer(){
    const queryClient = useQueryClient();
    return  useMutation((requestBody:{
          userInfluencerId:number,
          influencerFormId:number,
          data:IUserInfluencerFormRequest
        }) => UserManager.patchMeInfluencer(requestBody.userInfluencerId,requestBody.influencerFormId, requestBody.data),
        {
          onSuccess: async (data) => {
            await queryClient.invalidateQueries('userMe');
            await queryClient.invalidateQueries('userMeInfluencer');
          }
        }
    );
  }
  static  isInstanceOfIInfluencerUgcForm(influencerForm: any): influencerForm is IInfluencerUgcForm{
    return 'biography' in influencerForm;
  }
}
