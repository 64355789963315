import ApiService from "@/services/ApiService";
import env from "@/configs/env";
import {useQuery} from "vue-query";
import {$notify, notificationType} from "@/notify";
import {HTTPStatesWithDefaultNotificationEnum} from "@/entities/HTTPStatesEnum";

const client = ApiService.createApi();

export interface IChatV1{
    briefId:number;
    clientId:number;
    description:string | null;
    img:string | null;
    mail:string | null;
    name:string | null;
    telephone:string | number | null
}

export interface IChatMessagesV1{
    date:number;
    id:number;
    img:string | null;
    message:string | null;
    sender:number | null;
    to:number | null;
}
export default abstract class ChatV1Manager{

   static async getInfluencerChatClients(): Promise<IChatV1[]>{
        const response = await client.get(env.API_V1 + '/chat/getInfluenceurChatCLients');
        return response.data;
    }

    static async getChatClientMessages(
        data : {
                    clientId : number | null,
                    briefId : number | null
                }
    ):Promise<IChatMessagesV1[]>{
        const response = await client.post(env.API_V1 + '/chat/getChatClientMessages', data);
        return response.data;
    }

    static async getInfluencerChatUnread(

    ):Promise<string[]>{
       const response = await client.get(env.API_V1 + '/brief/chat_notif');
       return response.data;
    }

    static async postChatMessage(
        data:{
            to: number| null;
            briefId: number | null;
            message: string | null;
        }
    ){
       const response = await client.post(env.API_V1 + '/chat/envoyerBriefMessageInfluenceur', data);
       return response.data
    }

    static async postNotViewedMsg(
        data:{ id:number }
    ){
       const response = await client.post(env.API_V1 + '/chatClient/mettreEnNonLu', data);
       return response.data;
    }


    static getInfluencerChatClientsQuery(){
        return useQuery('influencerChatV1', this.getInfluencerChatClients,{
            onError: (error:any) => {
                if( !HTTPStatesWithDefaultNotificationEnum.find(elt => elt === error.response.status ) ) {
                    $notify.addNotif({
                        title: 'influencer.errorTitle',
                        content: "global.somethingWentWrong",
                        icon: 'mdi-error',
                        type: notificationType.ERROR
                    });
                }
            }
        });
    }

    static getChatClientMessagesQuery(
        data : {
                    clientId : number | null,
                    briefId : number | null
                }
    ){
        return useQuery(['clientMessageV1',data],  () => this.getChatClientMessages(data));
    }


}