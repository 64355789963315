export default {
  "SorryNotGoodNavigator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, ton navigateur ne suporte pas ce format de vidéo"])},
  "agreeToConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte les conditions générales de cette campagne"])},
  "applicationCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature annulée"])},
  "applyForBrief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je postule"])},
  "applySuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta candidature a bien été prise en compte"])},
  "associateToInstagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecte ton compte Instagram via Facebook"])},
  "biography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biographie"])},
  "brandNotResponding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La marque ne me répond pas"])},
  "briefTrackingPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails des publications de "])},
  "briefsDetailsPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief de la campagne"])},
  "cancelApplicationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dis-nous pourquoi ?"])},
  "cancelApplicationPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler ma candidature à la campagne"])},
  "chatCancelApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler ma candidature"])},
  "chatMessageNotViewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lu"])},
  "chatReportIssue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un problème"])},
  "chatSeeBrief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la campagne"])},
  "chatSeePost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir mes publications"])},
  "collaborationRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je crée mon compte Instagram"])},
  "commentsEngagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement commentaires"])},
  "compareToRegularRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en comparaison avec sa moyenne"])},
  "completeInfluencerForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complète tes informations"])},
  "connectedToStripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iban à jour"])},
  "createPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer mon mot de passe"])},
  "createUgcAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crée ton profile UGC"])},
  "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démonstration du produit"])},
  "descriptionIssueLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du problème rencontré *"])},
  "descriptionIssuePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez-nous le problème rencontré avec cette marque"])},
  "deviceOptionProCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera professionnelle"])},
  "deviceOptionSmartphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartphone"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée du jeu concours"])},
  "else": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
  "encounteredIssueLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème rencontré"])},
  "endowmentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur de la dotation produit"])},
  "errorApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de l'enregistrement de ta candidature"])},
  "errorCancelApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu ne peux pas annuler cette candidature"])},
  "errorCantReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu ne peux pas signaler de problème sur cette candidature"])},
  "errorMsgChatMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenue lors de l'envoie de ton message,  réessaie plus tard."])},
  "errorMsgDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la mise à jour de tes infos de Livraisons."])},
  "errorMsgSetMsgToNotViewedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenue, réessaie plus tard."])},
  "errorMsgUpdateBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la mise à jour de tes infos de paiement."])},
  "errorMsgUpdateBillingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la mise à jour de ton moyen de rémunération."])},
  "errorMsgUpdateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la mise à jour de ton e-mail."])},
  "errorMsgUpdateNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la mise à jour de tes réseaux sociaux."])},
  "errorMsgUpdatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la mise à jour de ton mot de passe."])},
  "errorMsgUpdateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de la mise à jour de ton profil."])},
  "errorMsgYouShouldUpdateIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu dois enregistrer ton IBAN pour continuer."])},
  "errorNewUserInfluencer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu as déja un compte UGC"])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "errorTitleNewUserInfluencer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
  "exampleImageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple de photo"])},
  "exampleVideoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple de vidéo"])},
  "gameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeu concours"])},
  "genderOptionMan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
  "genderOptionWoman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])},
  "guidelinesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guidelines"])},
  "hasPosted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications en cours de validation par la marque"])},
  "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
  "hintTiktok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ton pseudo"])},
  "hintTwitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ton pseudo"])},
  "hours_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 heures"])},
  "hours_72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["72 heures"])},
  "ibanIsSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN enregistré"])},
  "imageAndVideoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images & videos"])},
  "influencerApplicationPopupAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "influencerApplicationPopupApplyToBrief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postule pour cette campagne"])},
  "influencerApplicationPopupBillingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de facturation"])},
  "influencerApplicationPopupCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "influencerApplicationPopupCompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
  "influencerApplicationPopupCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "influencerApplicationPopupDeliveryData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de livraison"])},
  "influencerApplicationPopupIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
  "influencerApplicationPopupPaymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de rémuneration"])},
  "influencerApplicationPopupPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "influencerApplicationPopupSiret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siret"])},
  "influencerApplicationPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature à la campagne"])},
  "influencerApplicationPopupZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "influencerFormPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalise ton inscription"])},
  "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "labelAllowFindly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'autorise Findly à éditer mes factures"])},
  "labelBiography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parle nous de toi"])},
  "labelBirthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "labelChatAllBrief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les campagnes"])},
  "labelChatAllBriefStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les statuts"])},
  "labelChatSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche une marque"])},
  "labelCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
  "labelCompanyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de l'entreprise"])},
  "labelCompanyCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville de l'entreprise"])},
  "labelCompanyCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de l'entreprise"])},
  "labelCompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
  "labelCompanyPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone de l'entreprise"])},
  "labelCompanySiret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siret"])},
  "labelCompanyZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal de l'entreprise"])},
  "labelConfirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme ton mot de passe"])},
  "labelCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "labelCurrentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
  "labelDeliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison"])},
  "labelDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel matériel utilises-tu"])},
  "labelEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel e-mail"])},
  "labelFirstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "labelGender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
  "labelGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte cadeau"])},
  "labelHuman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tu es un humain"])},
  "labelIdioms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelles langues parles-tu?"])},
  "labelKeywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots clés"])},
  "labelLocalisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation"])},
  "labelLoginEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail de connexion"])},
  "labelName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "labelNetworkInstagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "labelNetworkSnapchat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snapchat"])},
  "labelNetworkTiktok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiktok"])},
  "labelNetworkTwitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitch"])},
  "labelNetworkYoutube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube"])},
  "labelNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "labelPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton mot de passe"])},
  "labelPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virement"])},
  "labelPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "labelTVA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je facture la TVA"])},
  "labelTheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thèmes"])},
  "labelUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton pseudo de créateur d'UGC"])},
  "labelZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
  "lateVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancienne version"])},
  "likeEngagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement like"])},
  "linkToIntagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lie ton compte instagram"])},
  "maxImageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La taille maximale de l'image est de 2 Mo"])},
  "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
  "moreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["infos"])},
  "myContents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes contenus"])},
  "myFindlyAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte Findly"])},
  "myReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes reviews"])},
  "nbCumulativeView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vues cumulées"])},
  "nbExits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de sorties"])},
  "nbReplies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de réponses"])},
  "nbSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrements"])},
  "nbUniqueView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vues uniques"])},
  "nbWinners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de gagnant"])},
  "needEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseigne ton e-mail de connexion"])},
  "needUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin de mettre à jour ton IBAN"])},
  "noDataSelectedBriefStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les status"])},
  "noPostHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de publications sur cette campagne."])},
  "noStoryHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de story sur cette campagne."])},
  "notGoodUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu dois remplir une URL de type"])},
  "per_1000_followers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour 1000 followers"])},
  "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
  "portfolio": {
    "addPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une photo"])},
    "addPortfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter mon portfolio"])},
    "addVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une vidéo"])},
    "filterBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par"])},
    "noPhotosAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de photos ajoutées"])},
    "noVideosAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de vidéos ajoutées"])},
    "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
    "photoContents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes contenus photos"])},
    "reviews": {
      "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication"])},
      "globalScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note globale"])},
      "lastCollaborations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières collaborations"])},
      "lastReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derniers avis"])},
      "noReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas encore de reviews"])},
      "postsQuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualité des contenus"])},
      "reactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réactivité"])}
    },
    "ugcType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de contenu UGC"])},
    "updatePortfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mon portfolio"])},
    "videoContents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes contenus vidéos"])},
    "videoDemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démo produit"])},
    "videoTestimonial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidéo témoignage"])},
    "videoUnboxing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unboxing Vidéo"])}
  },
  "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
  "postValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagne Terminée"])},
  "proCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caméra professionelle"])},
  "productNotReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je n'ai pas reçu le produit / service"])},
  "productValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur du produit"])},
  "promoCode1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une réduction de"])},
  "promoCode2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec le code"])},
  "publishedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié le"])},
  "publishedFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publier du"])},
  "publishedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])},
  "receivedComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires reçus"])},
  "receivedLikes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Likes reçus"])},
  "reels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reels"])},
  "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusé(e)"])},
  "reportIssuePopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un problème sur la campagne"])},
  "reported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signalé(e)"])},
  "same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pareil"])},
  "saveIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer mon IBAN"])},
  "setToNotVeiwed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marquer comme non lu"])},
  "shouldBeTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coché"])},
  "smartphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartphone"])},
  "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Story"])},
  "stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plateforme de paiement sécurisée"])},
  "subscribedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrit le"])},
  "subtitleBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir mes paiements"])},
  "subtitleBilling2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de facturation"])},
  "subtitleBilling3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
  "subtitleBilling3bis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - (où je reçois mes paiements)"])},
  "subtitleConfigureProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure tes informations de connexion"])},
  "subtitleEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "subtitleEmailPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "successApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta candidature a bien été prise en compte"])},
  "successCancelApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta candidature à bien été annulée"])},
  "successIssueReporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton signalement à bien été envoyé"])},
  "successMsgDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes infos de livraison ont été mises à jour avec succès"])},
  "successMsgUpdateBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes infos de paiement ont été mises à jour avec succès."])},
  "successMsgUpdateBillingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton moyen de rémunération a été mis à jour avec succès."])},
  "successMsgUpdateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton e-mail a été mis à jour avec succès"])},
  "successMsgUpdateNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes réseaux ont été mis à jour avec succès"])},
  "successMsgUpdatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton mot de passe a été mis à jour avec succès"])},
  "successMsgUpdateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton profil a été mis à jour avec succès"])},
  "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
  "tabTitleBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
  "tabTitleConnexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "tabTitleDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison"])},
  "tabTitleNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseaux"])},
  "tabTitleProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profils"])},
  "tabTitleUgc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UGC"])},
  "tagLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien tag"])},
  "testimonial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video testimonielle"])},
  "textCancelApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es-tu sûr de vouloir annuler ta candidature à la campagne"])},
  "theProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le produit"])},
  "titleChatView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messagerie"])},
  "titleSettingsView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du profil"])},
  "ugc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UGC"])},
  "ugcRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis un créateur d'UGC"])},
  "unboxing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video d'unboxing"])},
  "underStudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours d'étude"])},
  "updateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mon e-mail"])},
  "updateIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mon IBAN"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mon mot de passe"])},
  "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé(e), en attente des publications"])},
  "validationNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de validation"])},
  "warningBlockedPopup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fenêtre n'a pas pu être ouverte, un bloqueur de popup doit-être activé sur ton navigateur! Désactive-le et réessaie."])},
  "warningMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tes changements ne seront pas pris en compte."])},
  "warningTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
  "week_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une semaine"])},
  "yearsOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])}
}