<template>
    <div>
        <v-row
                :no-gutters="true"
        >
            <p class="text-h6 text-primary text-center pb-5">
                {{ $t('global.notRegisteredAsInstagramInfluencer') }}
            </p>
        </v-row>
    </div>
</template>

<script lang="ts" setup>


</script>

<style scoped>

</style>