import env from "@/configs/env";
import ApiService from "@/services/ApiService";
import {useQuery} from "vue-query/esm";
import {WritableComputedRef} from "vue";

export interface IPicture {
    id: number | null;
    url: string;
    signedUrl: string;
    urlWithToken:string;
    mimeType:string;
    originalFilename:string;
}


const axios = ApiService.createApi();

export default class MediaManager {

    static async getMediaFile(
        fileName:string): Promise<IPicture> {
        const response = await axios.get(env.API_V2 + "/media/image/"+ fileName);
        return response.data;
    }

    static async postMediaFile(
        fileSystem: string,
        data: WritableComputedRef<FormData> | FormData){
        const response = await axios.post(env.API_V2 + "/media/" + fileSystem, data);
        return response.data;
    }

}