// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
import { components, directives, ThemeDefinition } from "vuetify/dist/vuetify";
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";
import { en, fr } from "vuetify/locale";

const findlyTheme: ThemeDefinition = {
  dark: false,
  colors: {
    surface: "#FFFFFF",
    primary: "#5739F0",
    "primary-darken-1": "#3700B3",
    secondary: "#03DAC6",
    "secondary-darken-1": "#018786",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

const menuTheme: ThemeDefinition = {
  dark: false,
  colors: {
    surface: "#1C2047",
    primary: "#1C2047",
    "primary-darken-1": "#3700B3",
    secondary: "#5739F0",
    "secondary-darken-1": "#018786",
    error: "#B00020",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FB8C00",
  },
};

export default createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: "findlyTheme",
    themes: { findlyTheme, menuTheme },
  },
  locale: {
    defaultLocale: "en",
    fallbackLocale: "en",
    messages: { en, fr },
  },
});
