import {createApp} from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./routes";
import i18n from "./vendors/i18n";
import {VueQueryPlugin} from "vue-query";
import vuetify from "./vendors/vuetify";
import {loadFonts} from "./vendors/webfontloader";
import "@/types/Window.ts";
import "@/assets/scss/main.scss";
import JwtTokenService from "@/services/JwtTokenService";

loadFonts();

let urlSearchParams = new URLSearchParams(window.location.search);
let jwtToken = urlSearchParams.get('jwt_token');
if (jwtToken) {
	const payload = JwtTokenService.parseJwt(jwtToken);
	if (payload) {
		let data: {
			token: string,
			refreshToken: string | undefined,
			expirationDate: number | undefined
		} = {
			token: jwtToken,
			refreshToken: undefined,
			expirationDate: payload?.exp
		};

		let refreshToken = urlSearchParams.get('refresh_token');
		if (refreshToken) {
			data.refreshToken = refreshToken;
		}

		localStorage.setItem(
			"security",
			JSON.stringify(data)
		);
		localStorage.setItem("username", payload.username);
		window.location.replace(location.protocol + '//' + location.host);
	}


}

const app = createApp(App)
	.use(i18n)
	.use(router)
	.use(VueQueryPlugin, {
		queryClientConfig: {
			defaultOptions: {queries: {staleTime: 10 * 60 * 1000}},
		},
	})
	.use(vuetify);


app.mount("#app");
