export default {
  "analysis": {
    "community": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Àge et genre de la communauté"])}
    },
    "interest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérêt de la communauté"])}
    },
    "location": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation de la communauté"])}
    }
  },
  "analysisTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de la communauté"])},
  "chart": {
    "men": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hommes"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])},
    "women": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femmes"])}
  },
  "commentsAverage": {
    "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une publications reçois en général ", _interpolate(_named("comments")), " commentaires."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre moyen de commentaires"])}
  },
  "commentsEngagement": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'engagement commentaire"])}
  },
  "globalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques génériques"])},
  "influencerCard": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse électronique"])},
    "followers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnés"])},
    "followings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnements"])},
    "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
    "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site web"])}
  },
  "likeAverage": {
    "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Une publication obtient en moyenne ", _interpolate(_named("likes")), " likes."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre moyen de likes"])}
  },
  "likesEngagement": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'engagement like"])}
  },
  "nbPostsPerWeek": {
    "subtitle": {
      "few": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peu fréquent"])},
      "toMuch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trop souvent"])},
      "usual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habituel"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de messages par semaine"])}
  },
  "publicationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publications"])},
  "reach": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de vues uniques"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portée mondiale"])}
  }
}