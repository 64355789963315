<template>
  <div class="fullscreen">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss">
.fullscreen {
  margin: auto;
  width: 100vw;
  height: 100vh;
  padding-bottom: 5rem;
  overflow-y: scroll;
  display: flex;
  background-size: cover;
  background-image: url("@/assets/img/security/LoginPage/background.jpg");
}
</style>
