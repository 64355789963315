import axios from "axios";
import SecurityToken from "@/entities/SecurityToken";
import env from "@/configs/env";
import ApiService from "@/services/ApiService";
import JwtTokenService from "@/services/JwtTokenService";
import CrispService from "@/services/CrispService";
import SmartlookService from "@/services/SmartlookService";
import {IUserResponse} from "@/entities/User";

export enum PlatformEnum {
    CREDENTIALS = "Credentials",
    REFRESH_TOKEN = "RefreshToken",
    FACEBOOK = "Facebook",
}

interface IBasePlatform {
    platform: PlatformEnum;
}

interface IBaseSignUp {
    email: string;
    password: string;
}

export interface ICredentialPlatform extends IBasePlatform {
    username: string;
    password: string;
    shortLifeRefreshToken: boolean;
}

export interface IRefreshTokenPlatform extends IBasePlatform {
    refreshToken: string;
}

export interface IFacebookTokenPlatform extends IBasePlatform {
    accessToken: string;
    shortLifeRefreshToken: boolean;
}

export interface IFacebookCodePlatform extends IBasePlatform {
    code: string;
    shortLifeRefreshToken: boolean;
}

export interface IForgotPassword {
    email: string;
    resetPasswordUrl: string;
}

interface IResetPassword {
    password: string;
    token: string;
}

export interface IResetPasswordInput extends IResetPassword {
    confirmPassword: string;
}

export default abstract class SecurityManager {
    static async getToken(
        data: ICredentialPlatform | IRefreshTokenPlatform | IFacebookTokenPlatform | IFacebookCodePlatform
    ): Promise<SecurityToken> {
        const response = await axios.post(env.API_V2 + "/token", data);
        return response.data;
    }

    static async influencerSignUp(data: IBaseSignUp) {
        const response = await axios.post(env.API_V2 + "/influencer/signUp", data);
        return response.data;
    }

    static async forgotPassword(data: IForgotPassword) {
        const client = ApiService.createApi();
        const response = await client.post("forgottenPasswordAsk", data);

        return response.data;
    }

    static async resetPassword(data: IResetPassword) {
        const client = ApiService.createApi();
        const response = await client.post("forgottenPasswordChange", data);
    }

    static async refreshToken(refreshToken: string) {
        const payload: IRefreshTokenPlatform = {
            platform: PlatformEnum.REFRESH_TOKEN,
            refreshToken: refreshToken,
        };

        const response = await SecurityManager.getToken(payload);

        localStorage.setItem(
            "security",
            JSON.stringify({...response, expirationDate: JwtTokenService.getPayload()?.exp})
        );
        CrispService.reset();
        CrispService.configure();
        SmartlookService.configure();

        return response.token;
    }

    static async postVerifyUserMail(data:{ platform: string,refreshToken: string}):Promise<IUserResponse>{
        const client = ApiService.createApi();
        return await client.post("/verifyUserMail", data);
    }

    static async postResendRefreshToken(
        data: { user: string },
    ): Promise<any> {
        const client = ApiService.createApi();
        return await client.post('/resendRefreshToken', data);
    }

}
