export default {
  "actionMenu": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel my application"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a message"])},
    "publications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See my publications for this campaign"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report an issue on this campaign"])}
  },
  "briefUgcUploadContent": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import your UGC contents"])}
  },
  "cancelDialog": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why do you want to cancel?"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel your application?"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel my application"])}
  },
  "cardUgc": {
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your UGC content has been deleted"])},
    "sendUgc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send my UGC content to the brand"])},
    "ugcRefused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UGC content refused by the brand"])},
    "ugcSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent UGC content"])},
    "updateContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify my contents"])},
    "uploadSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your UGC content has been sent"])},
    "validatedByBrand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UGC contents validated by the brand"])}
  },
  "endowment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endowment"])},
  "reportDialog": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of the issue"])},
    "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue encountered"])},
    "reasons": {
      "noAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mark doesn't respond"])},
      "noProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I didn't receive the product/service"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report an issue on campaign"])}
  },
  "select": {
    "addedByCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All status"])},
    "applicationCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
    "hasPosted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has posted"])},
    "postValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
    "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refused"])},
    "reported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reported"])},
    "selectAStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a brief status"])},
    "underStudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under study"])},
    "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated (waiting for publications)"])},
    "validatedV1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated"])},
    "validationNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for validation"])}
  },
  "table": {
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign's name"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remuneration"])},
    "promoCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo code"])},
    "publicationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing date(s)"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
  },
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Campaigns"])}
}