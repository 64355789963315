export default {
  "SorryNotGoodNavigator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry your navigator doesn't support this video format"])},
  "agreeToConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to this brief conditions"])},
  "applicationCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Canceled"])},
  "applyForBrief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply for this Brief"])},
  "applySuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your application is successful"])},
  "associateToInstagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associate your Findly account with your Instagram account"])},
  "biography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biography"])},
  "brandNotResponding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The brand isn't responding me"])},
  "briefTrackingPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts' details of"])},
  "briefsDetailsPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief of"])},
  "cancelApplicationLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us why"])},
  "cancelApplicationPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel my application to the campaign : "])},
  "chatCancelApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel your application"])},
  "chatMessageNotViewed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not viewed"])},
  "chatReportIssue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report an issue"])},
  "chatSeeBrief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See brief"])},
  "chatSeePost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See your posts"])},
  "collaborationRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I create may instagram account"])},
  "commentsEngagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment engagement"])},
  "compareToRegularRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["compare to regular rate"])},
  "completeInfluencerForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete your informations"])},
  "connectedToStripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iban up to date"])},
  "createPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure password"])},
  "createUgcAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your UGC account"])},
  "demo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo video"])},
  "descriptionIssueLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of your issue"])},
  "descriptionIssuePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe the issue encountered with the brand"])},
  "deviceOptionProCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional camera"])},
  "deviceOptionSmartphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartphone"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game contest duration"])},
  "else": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something else"])},
  "encounteredIssueLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encountered Issue"])},
  "endowmentValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product's endowment with a value of"])},
  "errorApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong with your application"])},
  "errorCancelApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't cancel this application"])},
  "errorCantReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application couldn't be reported"])},
  "errorMsgChatMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured while sending your message, please try again later"])},
  "errorMsgDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An issue occurred while editing your delivery data."])},
  "errorMsgSetMsgToNotViewedMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured please try again later"])},
  "errorMsgUpdateBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An issue occurred while editing your billing data."])},
  "errorMsgUpdateBillingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An issue occurred while editing your billing type."])},
  "errorMsgUpdateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An issue occurred while editing your email."])},
  "errorMsgUpdateNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An issue occurred while editing your networks."])},
  "errorMsgUpdatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An issue occurred while editing your password."])},
  "errorMsgUpdateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An issue occurred while editing your profile."])},
  "errorMsgYouShouldUpdateIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to register your Iban to continue."])},
  "errorNewUserInfluencer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have a UGC account"])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
  "errorTitleNewUserInfluencer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warning"])},
  "exampleImageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo example"])},
  "exampleVideoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video example"])},
  "gameTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game contest"])},
  "genderOptionMan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man"])},
  "genderOptionWoman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woman"])},
  "guidelinesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guidelines"])},
  "hasPosted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has Posted"])},
  "hashtags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hashtags"])},
  "hintTiktok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your-user-name"])},
  "hintTwitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your-user-name"])},
  "hours_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24 hours"])},
  "hours_72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["72 hours"])},
  "ibanIsSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saved IBAN"])},
  "imageAndVideoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images & videos"])},
  "influencerApplicationPopupAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "influencerApplicationPopupApplyToBrief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply to the campaign"])},
  "influencerApplicationPopupCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "influencerApplicationPopupCompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
  "influencerApplicationPopupCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "influencerApplicationPopupDeliveryData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery data"])},
  "influencerApplicationPopupIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
  "influencerApplicationPopupPaymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remuneration type"])},
  "influencerApplicationPopupPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
  "influencerApplicationPopupSiret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siret"])},
  "influencerApplicationPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application to brief"])},
  "influencerApplicationPopupZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
  "influencerFormPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalise your registration"])},
  "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "labelAllowFindly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I allow Findly to edit my invoices"])},
  "labelBiography": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us about you"])},
  "labelBirthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday's date"])},
  "labelChatAllBrief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All briefs"])},
  "labelChatAllBriefStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All briefs' status"])},
  "labelChatSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a brand"])},
  "labelCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "labelCompanyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company's address"])},
  "labelCompanyCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company's city"])},
  "labelCompanyCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company's country"])},
  "labelCompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company's name"])},
  "labelCompanyPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company's phone number"])},
  "labelCompanySiret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siret"])},
  "labelCompanyZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company's zip code"])},
  "labelConfirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm your password"])},
  "labelCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "labelCurrentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
  "labelDeliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Address"])},
  "labelDevice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kind of device do you work with"])},
  "labelEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New email"])},
  "labelFirstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firstname"])},
  "labelGender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
  "labelGiftCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift card"])},
  "labelHuman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you human"])},
  "labelIdioms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which languages do you speak?"])},
  "labelKeywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords"])},
  "labelLocalisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation"])},
  "labelLoginEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login e-mail"])},
  "labelName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "labelNetworkInstagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "labelNetworkSnapchat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snapchat"])},
  "labelNetworkTiktok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiktok"])},
  "labelNetworkTwitch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitch"])},
  "labelNetworkYoutube": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Youtube"])},
  "labelNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "labelPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password"])},
  "labelPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
  "labelPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
  "labelTVA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I invoice with VAT"])},
  "labelTheme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Themes"])},
  "labelUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your UGC creator's pseudo"])},
  "labelZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
  "lateVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Late version"])},
  "likeEngagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like engagement"])},
  "linkToIntagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to your Instagram's account"])},
  "maxImageSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum image size is 2 MB"])},
  "mentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions"])},
  "moreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More infos"])},
  "myContents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My contents"])},
  "myFindlyAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Findly account"])},
  "myReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My reviews"])},
  "nbCumulativeView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number cumulative viewed"])},
  "nbExits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of exits"])},
  "nbReplies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of replies"])},
  "nbSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of save"])},
  "nbUniqueView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number unique viewed"])},
  "nbWinners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of winner"])},
  "needEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register your login e-mail"])},
  "needUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Needing to update your IBAN"])},
  "noDataSelectedBriefStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all status"])},
  "noPostHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No post for this brief."])},
  "noStoryHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No story for this brief."])},
  "notGoodUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must enter a URL like"])},
  "per_1000_followers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per 1000 followers"])},
  "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
  "portfolio": {
    "addPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add picture"])},
    "addPortfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add my portfolio"])},
    "addVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add video"])},
    "filterBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by"])},
    "noPhotosAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No photos have been added yet"])},
    "noVideosAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No videos have been added yet"])},
    "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picture"])},
    "photoContents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Picture contents"])},
    "reviews": {
      "communication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication"])},
      "globalScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global score"])},
      "lastCollaborations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last collaborations"])},
      "lastReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last reviews"])},
      "noReviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No reviews yet"])},
      "postsQuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts quality"])},
      "reactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reactivity"])}
    },
    "ugcType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UGC content type"])},
    "updatePortfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update my portfolio"])},
    "videoContents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video contents"])},
    "videoDemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo product"])},
    "videoTestimonial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video testimonial"])},
    "videoUnboxing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unboxing Video"])}
  },
  "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
  "postValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished"])},
  "proCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnal camera"])},
  "productNotReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I haven't received the product / service"])},
  "productValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product value"])},
  "promoCode1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a"])},
  "promoCode2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reduction with the code"])},
  "publishedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published at"])},
  "publishedFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Published from"])},
  "publishedTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "receivedComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received comments"])},
  "receivedLikes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received likes"])},
  "reels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reels"])},
  "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refused"])},
  "reportIssuePopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a issue with campaign :"])},
  "reported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reported"])},
  "same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same"])},
  "saveIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save my IBAN"])},
  "setToNotVeiwed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set to not viewed"])},
  "shouldBeTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["true"])},
  "smartphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartphone"])},
  "story": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Story"])},
  "stripe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secured payments' platform"])},
  "subscribedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed at"])},
  "subtitleBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive your payments"])},
  "subtitleBilling2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing address"])},
  "subtitleBilling3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iban"])},
  "subtitleBilling3bis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" - (where i receive my payments)"])},
  "subtitleConfigureProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure your account login"])},
  "subtitleEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "subtitleEmailPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "successApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your application was successfully registered"])},
  "successCancelApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your application was successfully canceled"])},
  "successIssueReporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your report was successfully registered"])},
  "successMsgDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your delivery data have been successfully changed."])},
  "successMsgUpdateBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your billing data have been successfully changed."])},
  "successMsgUpdateBillingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your billing type has been successfully changed."])},
  "successMsgUpdateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email has been successfully changed."])},
  "successMsgUpdateNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your networks have been successfully updated."])},
  "successMsgUpdatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been successfully changed."])},
  "successMsgUpdateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your profile has been successfully edited."])},
  "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "tabTitleBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing"])},
  "tabTitleConnexion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])},
  "tabTitleDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
  "tabTitleNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
  "tabTitleProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiles"])},
  "tabTitleUgc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UGC"])},
  "tagLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag link"])},
  "testimonial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonial video"])},
  "textCancelApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure of wanting to cancel your application to the campaign : "])},
  "theProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The product"])},
  "titleChatView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
  "titleSettingsView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile settings"])},
  "ugc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UGC"])},
  "ugcRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm a UGC creator"])},
  "unboxing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unboxing vidéo"])},
  "underStudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under Study"])},
  "updateEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit email"])},
  "updateIban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit my IBAN"])},
  "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit password"])},
  "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validated"])},
  "validationNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation needed"])},
  "warningBlockedPopup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the new window couldn't be opened, a popup blocker must be enabled in your browser! Disable it and retry"])},
  "warningMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beware, your changes wont be saved."])},
  "warningTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
  "week_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A week"])},
  "yearsOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years old"])}
}