export const Localisations = [
    'Abymes',
     'Aix-En-Provence',
     'Ajaccio',
     'Albi',
     'Ales',
     'Amiens',
     'Angers',
     'Angouleme',
     'Annecy',
     'Antibes',
     'Arles',
     'Arras',
     'Aubagne',
     'Auxerre',
     'Avignon',
     'Bastia',
     'Bayonne',
     'Beauvais',
     'Belfort',
     'Besancon',
     'Beziers',
     'Blois',
     'Bordeaux',
     'Boulogne-Sur-Mer',
     'Bourg-En-Bresse',
     'Bourges',
     'Brest',
     'Brive-La-Gaillarde',
     'Caen',
     'Cagnes-Sur-Mer',
     'Calais',
     'Cannes',
     'Carcassonne',
     'Castres',
     'Cayenne',
     'Chalons-En-Champagne',
     'Chalon-Sur-Saone',
     'Chambery',
     'Charleville-Mezieres',
     'Chartres',
     'Chateauroux',
     'Cherbourg-Octeville',
     'Cholet',
     'Clermont-Ferrand',
     'Colmar',
     'Compiegne',
     'Dijon',
     'Douai',
     'Dunkerque',
     'Evreux',
     'Fort-De-France',
     'Frejus',
     'Gap',
     'Grasse',
     'Grenoble',
     'Hyeres',
     'Istres',
     'La Rochelle',
     'La Roche-Sur-Yon',
     'Lamentin',
     'Laval',
     'Le Havre',
     'Le Mans',
     'Lille',
     'Limoges',
     'Lorient',
     'Lyon',
     'Mamoudzou',
     'Mantes-La-Jolie',
     'Marseille',
     'Martigues',
     'Meaux',
     'Melun',
     'Metz',
     'Montauban',
     'Montlucon',
     'Montpellier',
     'Mulhouse',
     'Nancy',
     'Nantes',
     'Narbonne',
     'Nevers',
     'Nice',
     'Nimes',
     'Niort',
     'Orleans',
     'Paris',
     'Pau',
     'Perpignan',
     'Poitiers',
     'Port',
     'Quimper',
     'Reims',
     'Rennes',
     'Rouen',
     'Royan',
     'Saint Martin',
     'Saint-Andre',
     'Saint-Brieuc',
     'Saint-Denis',
     'Saint-Etienne',
     'Saint-Laurent-Du-Maroni',
     'Saint-Louis',
     'Saint-Malo',
     'Saint-Nazaire',
     'Saint-Paul',
     'Saint-Pierre',
     'Saint-Quentin',
     'Salon-De-Provence',
     'Sete',
     'Strasbourg',
     'Tampon',
     'Tarbes',
     'Thionville',
     'Toulon',
     'Toulouse',
     'Tours',
     'Troyes',
     'Valence',
     'Valenciennes',
     'Vannes'
]