export default {
  "alpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value is not alphabetical"])},
  "alphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value must be alpha-numeric"])},
  "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value does not match all of the provided validators"])},
  "between": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The value must be between ", _interpolate(_named("min")), " and ", _interpolate(_named("max"))])},
  "decimal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value must be decimal"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value is not a valid email address"])},
  "integer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value is not an integer"])},
  "ipAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value is not a valid IP address"])},
  "lengthSiret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number must be 14 long."])},
  "macAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value is not a valid MAC Address"])},
  "maxIdioms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can chose 3 idioms at most"])},
  "maxKeywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can chose a maximum of 3 keywords"])},
  "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The maximum length allowed is ", _interpolate(_named("max"))])},
  "maxThemes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can chose a maximum of 3 themes"])},
  "maxValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The maximum value is ", _interpolate(_named("max"))])},
  "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The minimum length allowed is ", _interpolate(_named("min"))])},
  "minValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The minimum value allowed is ", _interpolate(_named("min"))])},
  "noSpaceSiret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please do not use spaces."])},
  "not": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value does not match the provided validator"])},
  "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value must be numeric"])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value does not match any of the provided validators"])},
  "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value is required"])},
  "requiredIf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value is required"])},
  "requiredSiret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your n° SIRET."])},
  "requiredUnless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value is required"])},
  "sameAs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The value must be equal to the ", _interpolate(_named("otherName")), " value"])},
  "sameAsTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept to continue"])},
  "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The value is not a valid URL address"])}
}