export default {
  "addToYourPortfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afin de pouvoir participer à une campagne UGC, alimente ton portfolio !"])},
  "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Findly"])},
  "appbar": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du compte"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes statistiques"])},
    "ugcProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil UGC"])}
  },
  "buttons": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrir de nouvelles campagnes"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "sendToFindly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à Findly"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
    "validateAndApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider et Postuler"])}
  },
  "cancelApplicationPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annule ta candidature à"])},
  "checkingYourMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification du mail"])},
  "completeYourPortfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complète ton portfolio"])},
  "completeYourUgcProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complète ton profil UGC"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
  "error403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu n'es pas autorisé à aller par ici !"])},
  "error500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons rencontré une erreur côté serveur."])},
  "fileShouldBeAnImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu dois choisir un fichier image (.jpeg , .jpg, .png ...). "])},
  "issueReported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le problème a bien été signalé, nous reviendrons vers toi !"])},
  "issueSendPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le signalement à été pris en compte"])},
  "joinCreatorTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoins l'équipe de créateurs et accède à encore plus de campagnes !"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours .."])},
  "media": {
    "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La taille de ton fichier est trop importante. La taille maximale est de 100Mo"])}
  },
  "menu": {
    "campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagnes"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messagerie"])},
    "myCampaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes campagnes"])},
    "myPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes paiements"])}
  },
  "noMoreResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'autres résultats"])},
  "notGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non renseigné"])},
  "notRegisteredAsInstagramInfluencer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu n'es pas inscrit ou tu n'as pas lié ton compte pour ce réseau. Rends-toi dans ton espace pour t'en charger et ainsi pouvoir postuler. "])},
  "notRegisteredAsUgcTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu n'es pas encore inscrit en tant que créateur UGC !"])},
  "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
  "reportAnIssue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un problème"])},
  "reportIssuePopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un problème sur la campagne "])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "sendToFindly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à Findly"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème est survenu !"])},
  "stories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stories"])},
  "stripeLogoAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logo stripe"])}
}