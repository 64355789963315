export default {
  "alreadyApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu as déjà postulé"])},
  "comingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bientôt disponible"])},
  "filterByNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre par réseaux"])},
  "knowMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
  "registerAsUgcCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M'inscrire comme créateur d'UGC"])},
  "seeBrief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir la campagne"])},
  "select": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les types de rémunération"])},
    "endowmentOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dotation"])},
    "payed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rémunérée"])}
  },
  "showDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le détail"])},
  "showDetailsComingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir en avant-première"])}
}