<template>
  <div class="iframe">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss">
.iframe {
  margin: auto;
  width: 100vw;
  height: 100vh;
}
</style>
