import {IInfluencerForm} from "@/entities/User";
import env from "@/configs/env";
import ApiService from "@/services/ApiService";
import {useQuery} from "vue-query/esm";


const client = ApiService.createApi();

export interface IInfluencerInstagram{
    urlProfilePicture: string|null;
    username: string|null;
    name: string|null;
    gender: number|null;
    category: [],
    biography:string|null;
    email:string|null;
    city: string|null;
    nbFollowers: number|null;
    nbFollowings: number|null;
    nbPosts: number|null;
    likesAverage: number|null;
    commentsAverage: number|null;
    likesEngagement: number|null;
    commentsEngagement: number|null;
    commentsLikesRatio: number|null;
    lastPostDate:number|null;
    twoPostsInterval: number|null;
    nbPostsPerWeek: number|null;
    score: number|null;
    age: number|null;
    websiteLink: string|null;
    mainCity: {
        name: string|null;
    },
    rate: number|null;
    instagramPosts: [
        {
            url: string|null;
            caption: string|null;
            likes: number|null;
            comments: number|null;
            publishedAt: number|null;
        },
        {
            url: string|null;
            caption: string|null;
            likes: number|null;
            comments: number|null;
            publishedAt: number|null;
        }
    ],
    "instagramCommunityInterests": [
        {
            "interest":string|null;
            "percent": number|null;
        }
    ],
    "instagramCommunityAges": [],
    "instagramCommunityLocalisations": [
        {
            "localisation1": string|null;
            "localisation2": string|null;
            "localisation3": string|null;
            "localisation4": string|null;
            "localisation5": string|null;
            "percentLocalisation1": number|null;
            "percentLocalisation2": number|null;
            "percentLocalisation3": number|null;
            "percentLocalisation4": number|null;
            "percentLocalisation5": number|null;
        }
    ],
    "otherAccounts": {
        "tiktokInfluencers": any[],
        "instagramInfluencers": any[]
    }
}

export default class InfluencerUserManager{

    private static async getMeInstagram(
    ): Promise<IInfluencerForm> {
        const response = await client.get(env.API_V2 + "/influencerRole/me/instagram");
        return response.data;
    }

    static useGetMeInstagram(){
        return useQuery('userMeInstagram',this.getMeInstagram);
    }
}