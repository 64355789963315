import {RouteLocationNormalized, RouteRecordRaw} from "vue-router";
import BriefDetailsView from '@/components/brief/BriefDetailsView.vue';
import pako from 'pako';

const routes: Array<RouteRecordRaw> = [
    {
        path: "/embedded/brief",
        component: BriefDetailsView,
        meta: {
            layout: "EmbeddedLayout",
            allowAnonymous: true

        },
        props: (route: RouteLocationNormalized) => {
            const brief = JSON.parse(
                pako.inflateRaw(
                    Uint8Array.from<string>(
                        [...atob(<string>route.query.brief)],
                        (c: string) => c.charCodeAt(0)
                    ),
                    {to: 'string'}
                )
            );

            return {
                brief: brief
            };
        }
    }
];
export default routes;