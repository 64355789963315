import env from "@/configs/env";
import ApiService from "@/services/ApiService";
import {useQuery} from "vue-query/esm";
import {IPicture} from "@/managers/MediaManager";
import {Ref} from "vue";

export interface IRemunerationLaw{
    type: string |null;
    value: number |null;
}

export interface IBriefInfluencerCriteria{
    id: number |null;
    keywords: string[] |null;
    genders:  string[] |null;
    countries:  string[] |null;
    themes:  string[] |null;
    mainCities:  string[] |null;
    minimumFollowersNumber: number |null;
    maximumFollowersNumber:  number |null;
}
export interface IBriefTracking{
    id: number |null;
    hashtags:string[] |null;
    mentions: string[] |null;
}

export interface IBriefNetworkBriefDetails
{
    id: number |null;
    campaignFormats: string[] | null;
    remunerationLaws: IRemunerationLaw[];
    stepsToFollow: string[]| null;
    briefInfluencerCriteria: IBriefInfluencerCriteria | null;
    network: string | null;
    briefTracking: IBriefTracking | null
}

export interface IGameContestBriefDetails
{
    id: number |null;
    name: string |null;
    winnersNumber: number |null;
    description: string |null;
    value: number | null;
    rules:string[] |null;
    images: IPicture[];
    videos: IVideo[];
    duration: string;
}

export interface IPromoCodeBriefDetails {
    id?: number |null;
    code:  string | null;
    percent: number |null;
}
export interface IBrandBriefDetails{
    id: number |null;
    name: string |null;
    logo: IPicture;
    description:string
}
export interface IVideo{
    id: number |null;
    url: string | null;
    iframeUrl: string | null;
}

export interface IBriefDetails{
    id: number |null;
    title: string | null;
    description: string | null;
    startProspectingAt: string | null;
    webLink: string | null;
    startPublishingAt:  string | null;
    stopPublishingAt:  string | null;
    state: string | null;
    prospectingMailContent: string | null;
    briefNetworks: IBriefNetworkBriefDetails[];
    gameContest: IGameContestBriefDetails;
    promoCode:IPromoCodeBriefDetails;
    brand: IBrandBriefDetails;
    videos: IVideo[];
    images: IPicture[];
    cover: IPicture;
    webLinkWithUtm: string | null;
    hasApply:boolean | null;
}
export interface IBriefUgcDetails{
    id: number |null;
    title: string | null;
    description: string | null;
    webLink: string | null;
    startPublishingAt: null;
    stopPublishingAt: null;
    state: string | null;
    prospectingMailContent: string | null;
    briefNetworks: IBriefNetworkBriefDetails[];
    brand: IBrandBriefDetails;
    videos: IVideo[];
    images: IPicture[];
    cover: IPicture;
    webLinkWithUtm: string | null;
    hasApply:boolean | null;
    exampleVideos: IVideo[];
    exampleImages: IPicture[];
}
export type IBriefDetailWithProspection = IBriefDetails|IBriefUgcDetails;


const client = ApiService.createApi()

export default class BriefManager{
    private static async getBrief(
        id:number | undefined
    ):Promise<IBriefDetails|IBriefUgcDetails>
    {
        const response = await client.get(env.API_V2 + "/influencerRole/brief/" + id );
        return response.data;
    }

    static useGetBrief(
        id: Ref<number>,
        { enabled }: { enabled: boolean }
    )
    {
        return useQuery(['briefDetails', id], () => this.getBrief(id.value), {enabled});
    }

    static  isInstanceOfIBriefDetails(brief:IBriefDetailWithProspection): brief is IBriefDetails{
        return 'gameContest' in brief;
    }
    static  isInstanceOfIBriefUgcDetails(brief:IBriefDetailWithProspection): brief is IBriefUgcDetails{
        return 'exampleImages' in brief;
    }
}