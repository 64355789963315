import env from "@/configs/env";
import ApiService from "@/services/ApiService";
import {useQuery} from "vue-query/esm";
import {ComputedRef, Ref} from "vue";
import {useMutation} from "vue-query";


const client = ApiService.createApi();

export interface IPortfolioContentSearch {
    themes: string[];
    campaignFormats: string[];
}

export interface IPortfolioContentPost {
    themes: string[]
    description: string,
    campaignFormat: string,
    uploadedFile: string
}

export interface IPortfolioUploadedFile {
    id: number,
    mimeType: string,
    originalFileName: string,
    signedUrl: string
}

export interface IPortfolioContent {
    id: number;
    description: string;
    campaignFormat: string;
    themes: { id: number, name: string }[];
    uploadedFile: IPortfolioUploadedFile
}

export enum campaignFormatEnum {
    UGC_PHOTO = 'ugc_photo',
    UGC_VIDEO_TESTIMONIAL = 'ugc_video_testimonial',
    UGC_VIDEO_UNBOXING = 'ugc_video_unboxing',
    UGC_VIDEO_DEMO = 'ugc_video_demo'
}

export default abstract class PortfolioContentManager {
    static async postPortfolioContentSearch( params: IPortfolioContentSearch ):Promise<IPortfolioContent[]> {
        const response = await client.post(env.API_V2 + "/influencerRole/portfolioContent/search", params);
        return response.data;
    }

    static usePostPortfolioContentSearch(
        params: Ref<IPortfolioContentSearch> | ComputedRef<IPortfolioContentSearch>
    ) {
        return useQuery(['TrackedMedia', {params}], () => this.postPortfolioContentSearch(params.value));
    }

    static async postPortfolioContent( params: IPortfolioContentPost ) {
        const response = await client.post(env.API_V2 + "/influencerRole/portfolioContent", params);
        return response.data;
    }

    static async putPortfolioContent( id: string, params: IPortfolioContentPost ) {
        const response = await client.put(env.API_V2 + "/influencerRole/portfolioContent/" + id, params);
        return response.data;
    }

    static async deletePortfolioContent(id: string) {
        const response = await client.delete(env.API_V2 + "/influencerRole/portfolioContent/" + id);
        return response.data;
    }

    static usePostPortfolioContentMutation (){
        return useMutation((
            mutationRequest:{ themes: string[],
                description: string,
                campaignFormat: string,
                uploadedFile: string}) =>
            PortfolioContentManager.postPortfolioContent(mutationRequest)
        );
    }
    static usePutPortfolioContentMutation (){
        return useMutation((
                mutationRequest: {
                    portfolioContentId:string,
                    data: {
                        themes: string[],
                        description: string,
                        campaignFormat: string,
                        uploadedFile: string
                    }
                }
            ) =>
                PortfolioContentManager.putPortfolioContent(mutationRequest.portfolioContentId, mutationRequest.data)
        );
    }
}