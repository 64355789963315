export default {
  "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu as déjà un compte."])},
  "api": {
    "another_instagram_account_connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un autre compte Instagram est lié à ce compte. Contacte le support pour avoir plus d'informations."])},
    "instagram_account_connected_to_another_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce compte Instagram est déjà relié à une autre adresse mail. Contacte le support pour avoir plus d'informations."])},
    "missing_permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veille à activer toutes les permissions demandées."])},
    "no_instagram_business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu n'as pas mis de compte instagram business."])},
    "too_many_instagram_business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu as mis plus d'un compte instagram business."])}
  },
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'espace de connexion"])},
  "cgu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CGU"])},
  "companyLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter en tant que Marque"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme ton mot de passe"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "errorAlreadyExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un compte avec cet e-mail existe déjà."])},
  "errorInvalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe ou email invalide"])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
  "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
  "facebookHelp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Besoin d'aide pour passer par un compte instagram business ? ", _interpolate(_named("notice"))])},
  "forgotSumUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre ton e-mail pour recevoir les instructions afin de réinitialiser ton mot de passe."])},
  "forgotTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser ton mot de passe"])},
  "instagramAuthenticating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification Instagram en cours"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "loginSumUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heureux de te revoir, connecte-toi à ton compte !"])},
  "loginTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion Influenceur"])},
  "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulte notre notice !"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mots de passe"])},
  "politics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politique de confidentialité"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
  "registerSumUp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Crée ton compte et accède à des centaines de campagnes. En t'inscrivant, tu acceptes les ", _interpolate(_named("cgu")), " et la ", _interpolate(_named("politics")), " ."])},
  "registerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription influenceurs et créateurs UGC"])},
  "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se souvenir de moi"])},
  "resendRefreshTokenSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouveau mail de confirmation t'a été envoyé"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
  "resetSumUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre ton nouveau mot de passe"])},
  "resetTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser ton mot de passe"])},
  "sendNewVerificationMailTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un nouveau lien de confirmation à "])},
  "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendez-vous dans ta boîte mail ! Tu recevaras un mail de confirmation dans quelques instants ..."])},
  "successPasswordForgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un email t'a été envoyé"])},
  "successPasswordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bravo, ton mot de passe a bien été changé!"])},
  "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
  "verifyUserMailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La validation de ton mail à échouée."])},
  "verifyUserMailSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ton mail à bien été validé, tu peux te connecter à la plateforme"])}
}