import env from "@/configs/env";
import ApiService from "@/services/ApiService";
import { useQuery } from "vue-query";

export interface IStats {
  id: number;
  urlProfilePicture: string;
  username: string;
  name: string;
  gender: number;
  category: object;
  biography: string;
  email: string;
  city: string;
  nbFollowers: number;
  nbFollowings: number;
  nbPosts: number;
  likesAverage: number;
  commentsAverage: number;
  likesEngagement: number;
  likesEngagementPercent: number;
  commentsEngagement: number;
  commentsEngagementPercent: number;
  commentsLikesRatio: number;
  lastPostDate: number;
  twoPostInterval: number;
  nbPostsPerWeek: number;
  score: number;
  age: number;
  websiteLink: string;
  mainCity: {
    name: string;
    country: string;
  };
  reach: number;
  instagramCommunityInterests: Array<{ interest: string; percent: number }>;
  instagramCommunityAges: Array<{
    gender: number;
    segment13To17: string;
    segment18To24: string;
    segment25To34: string;
    segment35To44: string;
    segment45To54: string;
    segment55To64: string;
    segment65: string;
  }>;
  instagramCommunityLocalisations: Array<{
    country: string;
    localisation1: string;
    localisation2: string | null;
    localisation3: string | null;
    localisation4: string | null;
    localisation5: string | null;
    percentCountry: number;
    percentLocalisation1: number;
    percentLocalisation2: number | null;
    percentLocalisation3: number | null;
    percentLocalisation4: number | null;
    percentLocalisation5: number | null;
  }>;
  instagramPosts: Array<{
    caption: string;
    comments: number;
    likes: number;
    url: string;
    publishedAt: number;
  }>;
}

export default abstract class StatisticsManager {
  static async getMyStatistics(): Promise<IStats> {
    const client = ApiService.createApi();
    const response = await client.get(
      env.API_V2 + "/influencerRole/me/instagram"
    );
    return response.data;
  }

  static async getStatsQuery() {
    return useQuery("myStats", async () => this.getMyStatistics());
  }

  // UseQuery
  static usegetMyStatistics() {
    return useQuery('userStats', this.getMyStatistics);
  }
}
