import ApiService from "@/services/ApiService";
import {useQuery} from "vue-query/esm";


export interface IReportLabel{
    id:number;
    name:string;
    userRole:string[];
}
export interface IReportForm{
    content:string;
    reportedBrief:number|null;
    reportLabel:number|null;
    reportedInfluencerApplication:number|null;
}
const client = ApiService.createApi();
export default abstract class ReportManager{

    private static async getReportLabels(): Promise<IReportLabel[]> {
        const response = await client.get("/report/label");
        return response.data;
    }

    public static async postReport(
        data:IReportForm
    ){
        const response = await client.post("/report", data);
        return response.data;
    }

    public static  useGetReportLabels(){
        return useQuery("reportLabels", this.getReportLabels);
    }

}