export default {
  "alreadyApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already applied"])},
  "comingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon"])},
  "filterByNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by Network"])},
  "knowMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know more"])},
  "registerAsUgcCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register as UGC creator"])},
  "seeBrief": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "select": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All remuneration kind"])},
    "endowmentOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endowment"])},
    "payed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payed"])}
  },
  "showDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show details"])},
  "showDetailsComingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])}
}