export default {
  "addToYourPortfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So you can apply to campaign, complete your portfolio."])},
  "app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Findly"])},
  "appbar": {
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account settings"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My statistics"])},
    "ugcProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UGC profile"])}
  },
  "buttons": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more campaigns"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "sendToFindly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to Findly"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
    "validateAndApply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate and Apply"])}
  },
  "cancelApplicationPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel your application to"])},
  "checkingYourMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail checking"])},
  "completeYourPortfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete your portfolio"])},
  "completeYourUgcProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete your UGC profile"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "error403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your not allowed to go there !"])},
  "error500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We encounter an issue with the server."])},
  "fileShouldBeAnImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your file should be an image (.jpeg , .jpg, .png ...). "])},
  "issueReported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The issue was reported, we'll come back at you soon !"])},
  "issueSendPopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your report was taken care of"])},
  "joinCreatorTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join the creators team and access to more campaigns !"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading .."])},
  "media": {
    "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your media size is not rigth. The maximum size is 100 MB"])}
  },
  "menu": {
    "campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaigns"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
    "myCampaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My campaigns"])},
    "myPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My payments"])}
  },
  "noMoreResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more result"])},
  "notGiven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Known"])},
  "notRegisteredAsInstagramInfluencer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not registered or you have not linked your account for this network. Go to your space to take care of it and thus be able to apply."])},
  "notRegisteredAsUgcTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your not registered as a UGC creator"])},
  "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
  "reportAnIssue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report an issue"])},
  "reportIssuePopupTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report an issue on brief "])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "sendToFindly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send to Findly"])},
  "somethingWentWrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong !"])},
  "stories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stories"])},
  "stripeLogoAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stripe logo"])}
}