import {useQuery} from "vue-query/esm";
import ApiService from "@/services/ApiService";


const client = ApiService.createApi();

export interface Theme {
    id: number | null;
    name: string;
}

export interface Idiom {
    id: number;
    label: string;
    code: string;
}
export default abstract class DefaultManager{
    private static async getTheme(): Promise<Theme[]> {
        const response = await client.get("/theme");
        return response.data;
    }

    static useGetThemes() {
        return useQuery('themesList', this.getTheme);
    }
    private static async getIdiom(): Promise<Idiom[]> {
        const response = await client.get("/idiom");
        return response.data;
    }

    static useGetIdiom() {
        return useQuery('idiomList', this.getIdiom);
    }
}