import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";
import { usePreferredLanguages } from "@vueuse/core";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages<VueMessageType> = {};
  locales.keys().forEach((path: string) => {
    let parts = path
      .split("/")
      .filter((partPath: string) => partPath !== ".")
      .map((partPath: string) => partPath.replace(".json", ""));

    const locale: string | undefined = parts.shift();

    if (!locale) {
      return;
    }

    if (parts.length === 0) {
      messages[locale] = locales(path).default;
      return;
    }

    messages[locale] = {
      ...messages[locale],
      ...createObject(parts, locales(path).default),
    };
  });

  return messages;
}

function createObject(keys: string[], value: any): any {
  const currentKey = keys.shift();

  if (!currentKey) {
    return value;
  }

  return {
    [currentKey]: createObject(keys, value),
  };
}

function checkDefaultLanguage() {
  let matched = null;
  let languages = Object.getOwnPropertyNames(loadLocaleMessages());
  languages.forEach((lang) => {
    if (lang === navigator.language) {
      matched = lang;
    }
  });
  if (!matched) {
    languages.forEach((lang) => {
      let languagePartials = navigator.language.split("-")[0];
      if (lang === languagePartials) {
        matched = lang;
      }
    });
  }
  return matched;
}

export default createI18n({
  locale: checkDefaultLanguage() || process.env.VUE_APP_I18N || "fr",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "fr",
  messages: loadLocaleMessages(),
});
