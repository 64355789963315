import { defineComponent, h, PropType } from "vue";

import { Bar } from "vue-chartjs";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Plugin,
} from "chart.js";
import StatisticsManager from "@/managers/StatisticsManager";
import { useI18n } from "vue-i18n";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default defineComponent({
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object as PropType<Partial<CSSStyleDeclaration>>,
      default: () => {},
    },
    plugins: {
      type: Array as PropType<Plugin<"bar">[]>,
      default: () => [],
    },
  },
  async setup(props) {
    const { t } = useI18n({});

    const query = await StatisticsManager.getStatsQuery();
    let men: number[] = [];
    let women: number[] = [];

    query.data.value?.instagramCommunityAges.map((community) => {
      if (community.gender === 0) {
        women = [
          parseFloat(community.segment13To17),
          parseFloat(community.segment18To24),
          parseFloat(community.segment25To34),
          parseFloat(community.segment35To44),
          parseFloat(community.segment45To54),
          parseFloat(community.segment55To64),
          parseFloat(community.segment65),
        ];
      }
      if (community.gender === 1) {
        men = [
          parseFloat(community.segment13To17),
          parseFloat(community.segment18To24),
          parseFloat(community.segment25To34),
          parseFloat(community.segment35To44),
          parseFloat(community.segment45To54),
          parseFloat(community.segment55To64),
          parseFloat(community.segment65),
        ];
      }
    });

    const chartData = {
      labels: ["13-17", "18-24", "25-34", "35-44", "45-54", "55-64", "65+"],
      datasets: [
        {
          label: t("stats.chart.men"),
          backgroundColor: "#5739f0",
          data: men,
        },
        {
          label: t("stats.chart.women"),
          backgroundColor: "#ea5455",
          data: women,
        },
      ],
    };

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
    };

    return () =>
      h(Bar, {
        chartData,
        chartOptions,
        chartId: props.chartId,
        /*  width: props.width,
        height: props.height, */
        cssClasses: props.cssClasses,
        styles: props.styles,
        plugins: props.plugins,
      });
  },
});
