import JwtTokenService from "@/services/JwtTokenService";

export default abstract class SmartlookService {
    static configure(): void {
        if (!window.smartlook) {
            return;
        }

        const username = JwtTokenService.getPayload()?.username;

        if (username) {
            window.smartlook('identify', username);
        }
    }
}
