import ApiService from "@/services/ApiService";
import env from "@/configs/env";
import { useQuery } from "vue-query/esm";

const client = ApiService.createApi();

export interface IInfluencerApplicationCancel {
  id: number | null;
  influencer: {
    otherAccounts: string[];
    id: number | null;
  };
  state: string;
  remuneration: number | null;
  chat: {
    nbMessagesNotViewedOfCurrentUser: number | null;
  };
}

export interface IReviews {
  averageRate: number | null;
  averageCommunicationRate: number | null;
  averagePostsQualityRate: number | null;
  averageReactivityRate: number | null;
  elements: {
    postsQualityRate: number;
    comment: string;
    influencerApplication: {
      briefNetwork: {
        brief: {
          title: string;
          brand: {
            name: string;
            logo: {
              id: number;
              mimeType: string;
              originalFilename: string;
              signedUrl: string;
            };
          };
        };
      };
      state: string;
    };
    communicationRate: number;
    reactivityRate: number;
    rate: number;
  }[];
  nbElements: number | null;
}

export default abstract class InfluencerApplicationManager {
  private static async getAllStatus(): Promise<any> {
    const response = await client.get(
      env.API_V2 + "/influencerRole/brief/allStatus"
    );
    return response.data;
  }

  public static async postInfluencerApplicationNew(params: {
    briefId: number | null;
    briefNetworkId: number | null;
  }) {
    const response = await client.patch(
      env.API_V2 +
        "/influencerRole/brief/" +
        params.briefId +
        "/briefNetwork/" +
        params.briefNetworkId +
        "/influencerApplication"
    );
    return response.data;
  }

  public static async patchInfluencerApplicationCancel(
    influencerApplicationId: number,
    data: { content: string }
  ): Promise<IInfluencerApplicationCancel> {
    const response = await client.patch(
      env.API_V2 +
        "/influencerRole/influencerApplication/" +
        influencerApplicationId +
        "/cancel",
      data
    );
    return response.data;
  }

  static useGetAllStatus() {
    return useQuery("allInfluencerApplicationStatus", this.getAllStatus);
  }

  static async getInfluencerReviews(
    userInfluencerId: number
  ): Promise<IReviews> {
    const response = await client.get(
      "influencerRole/userInfluencer/" + userInfluencerId + "/review"
    );
    return response.data;
  }

  public static useGetInfluencerReviews(
    userInfluencerId: number,
    { enabled }: { enabled: boolean } = { enabled: true }
  ) {
    return useQuery(
      ["reviews", userInfluencerId],
      () => this.getInfluencerReviews(userInfluencerId),
      { enabled }
    );
  }

  static async getInfluencerCollaborations(userInfluencerId: number) {
    const response = await client.get(
      "influencerRole/userInfluencer/" + userInfluencerId + "/collaboration"
    );
    return response.data;
  }

  public static useGetInfluencerCollaborations(
    userInfluencerId: number,
    { enabled }: { enabled: boolean } = { enabled: true }
  ) {
    return useQuery(
      ["collaborations", userInfluencerId],
      () => this.getInfluencerCollaborations(userInfluencerId),
      { enabled }
    );
  }
}
