import env from "@/configs/env";
import ApiService from "@/services/ApiService";
import { useQuery } from "vue-query";
import {IPicture} from "@/managers/MediaManager";
import {NetworkEnum} from "@/entities/NetworkEnum";

export interface IMyCampaignsElements {
  elements:IMyCampaigns[],
  nbPages:number,
  nbElements:number,
  nbElementsPerPage:number
}

export interface IMyCampaigns {
  id: number,
  briefNetwork: {
    id: number,
    brief: {
      id: number,
      title: string,
      startProspectingAt:Date,
      startPublishingAt:Date,
      stopPublishingAt:Date,
      state: number,
      promoCode : {
        code: string,
        percent: number
      },
      brand: {
        id: number,
        name: string,
        logo: IPicture
      },
      cover: {
        id: number,
        url: string
      }
    },
    campaignFormats: string[]
    remunerationLaws: Array<{
      type:string,
      value: number
    }>,
    network:NetworkEnum
  },
  chat:{
    id:number
  },
  state: string,
  remuneration: number
}

export default abstract class MyCampaignsManager {
  static async getAllStatus(): Promise<string[]> {
    const client = ApiService.createApi();
    const response = await client.get(
      env.API_V2 + "/influencerRole/brief/allStatus"
    );
    return response.data;
  }

  static getAllStatusQuery() {
    return useQuery("AllStatus",this.getAllStatus);
  }

  static async postMyCampaigns(
    page: number | null,
    nbElementsPerPage: number | null,
    requestBody: {
      username: string,
      states: string[]
    },
  ): Promise<IMyCampaignsElements> {
    const client = ApiService.createApi();
    const response = await client.post(
      env.API_V2 + "/influencerRole/brief/search", requestBody, {params: {page: page, nbElementsPerPage:nbElementsPerPage} }

    );

    return response.data;
  }

}
