import ApiService from "@/services/ApiService";
import env from "@/configs/env";
import {useQuery} from "vue-query";


export interface IPaymentV1 {
    amount:number|null;
    date:number|null;
    id: number|null;
    emplacement?: string|null;
    imgBrief: string|null;
    name: string|null;
    stripeActive?: boolean|null;
}

const client = ApiService.createApi();

export default abstract class PaymentsV1Manager {

    private static async getPaymentV1(
    ):Promise<IPaymentV1[]>{
        const response = await client.get(env.API_V1 + '/espaceInfluenceur/getUserPayments');
        return response.data;
    }

    public static useGetPaymentV1(){
        return useQuery('listPaymentV1', this.getPaymentV1);
    }
}