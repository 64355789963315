import JwtPayload from "@/entities/JwtPayload";

export default abstract class JwtTokenService {
    static getToken(): string | undefined {
        const jwtToken = localStorage.getItem('security');

        if (!jwtToken) {
            return undefined;
        }

        return JSON.parse(jwtToken).token;
    }

    static getPayload(): JwtPayload | undefined {
        const jwtToken = this.getToken();

        if (!jwtToken) {
            return undefined;
        }

        return this.parseJwt(jwtToken);
    }

    static parseJwt(jwtToken: string): JwtPayload | undefined {
        return JSON.parse(atob(jwtToken.split(".")[1]));
    }
}
